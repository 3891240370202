import React from "react";

import TransparentCarousel from "../TransparentCarousel/TransparentCarousel"

import "./LynxaiVisionSecond.scss"

const homepage_1 = require('../../assets/images/homepage_1.png');
const homepage_2 = require('../../assets/images/homepage_2.png');

const data_collection_icon_black = require('../../assets/images/data_collection_icon_black.png');
const four_squares_icon_black = require('../../assets/images/four_squares_icon_black.png');
const server_icon_black = require('../../assets/images/server_icon_black.png');
const data_ml_ai_icon_black = require('../../assets/images/data_ml_ai_icon_black.png');
const real_time_icon_black = require('../../assets/images/real_time_icon_black.png');
const user_icon_black = require('../../assets/images/user_icon_black.png');

const carousel_data = {
    carousel_class: "third-carousel",
    colors: {
        font_color: "black",
        border_color: "darkgray"
    },
    carousel_data: {
        1: {
            icon: four_squares_icon_black,
            text: "Filtered data according to the client’s standards."
        },
        2: {
            icon: data_collection_icon_black,
            text: "Public data collection from social media, news, and reports."
        },
        3: {
            icon: server_icon_black,
            text: "Cross validation between sources to identify green washing and inaccurate or false data."
        },
        4: {
            icon: user_icon_black,
            text: "Objective data from suppliers, no subjective ratings and scores."
        },
        5: {
            icon: data_ml_ai_icon_black,
            text: "Data handled with ML and AI technologies to draw insights and statistics."
        },
        6: {
            icon: real_time_icon_black,
            text: "Real-Time data with news/social media trends and red flag monitoring."
        }
    }
}

const LynxaiVisionSecond = () => {
    return (
        <div className="lynxai-vision-second-div">
            <div className="lynxai-vision-second-inside-div">
                <div className="lynxai-vision-second-image-div">
                    <img className="lynxai-vision-second-image-1" src={homepage_1} alt="img"/>
                    <img className="lynxai-vision-second-image-2" src={homepage_2} alt="img"/>
                </div>
                <div className="lynxai-vision-second-info-div">
                    <div className="lynxai-vision-second-desktop lynxai-vision-second-title extra-large-text  font-style-montserrat-family-regular color-black">What is Lynxai Vision?</div>
                    <div className="lynxai-vision-second-desktop lynxai-vision-second-description medium-text lynxai-vision-second-description font-style-montserrat-family-regular color-black">Lynxai Vision aims to help asset managers with the difficulty that is dealing with billions of data points, from many different sources on the web, by taking care of the entire data pipeline. With our technology, we instantly provide real-time reliable ESG data to our clients, saving up to millions of dollars in builging teams and processes internally to tackle these challenges.</div>
                    <div className="lynxai-vision-second-icons-div-desktop">
                        <div className="lynxai-vision-second-points">
                            <img className="lynxai-vision-second-icon" src={four_squares_icon_black} alt="img"/>
                            <div className="small-text lynxai-vision-second-text font-style-montserrat-family-regular color-black">Filtered data according to the client’s standards.</div>
                        </div>
                        <div className="lynxai-vision-second-points">
                            <img className="lynxai-vision-second-icon" src={data_collection_icon_black} alt="img"/>
                            <div className="small-text lynxai-vision-second-text font-style-montserrat-family-regular color-black">Public data collection from social media, news, and reports.</div>
                        </div>
                        <div className="lynxai-vision-second-points">
                            <img className="lynxai-vision-second-icon" src={server_icon_black} alt="img"/>
                            <div className="small-text lynxai-vision-second-text font-style-montserrat-family-regular color-black">Cross validation between sources to identify green washing and inaccurate or false data.</div>
                        </div>
                        <div className="lynxai-vision-second-points">
                            <img className="lynxai-vision-second-icon" src={user_icon_black} alt="img"/>
                            <div className="small-text lynxai-vision-second-text font-style-montserrat-family-regular color-black">Objective data from suppliers, no subjective ratings and scores.</div>
                        </div>
                        <div className="lynxai-vision-second-points">
                            <img className="lynxai-vision-second-icon" src={data_ml_ai_icon_black} alt="img"/>
                            <div className="small-text lynxai-vision-second-text font-style-montserrat-family-regular color-black">Data handled with ML and AI technologies to draw insights and statistics.</div>
                        </div>
                        <div className="lynxai-vision-second-points">
                            <img className="lynxai-vision-second-icon" src={real_time_icon_black} alt="img"/>
                            <div className="small-text lynxai-vision-second-text font-style-montserrat-family-regular color-black">Real-Time data with news/social media trends and red flag monitoring.</div>
                        </div>
                    </div>
                    <div className="lynxai-vision-second-mobile lynxai-vision-second-title extra-large-text lynxai-vision-second-title font-style-montserrat-family-regular color-black">What is Lynxai Vision?</div>
                    <div className="lynxai-vision-second-mobile lynxai-vision-second-description medium-text font-style-montserrat-family-regular color-black">Lynxai Vision aims to help asset managers with the difficulty that is dealing with billions of data points, from many different sources on the web, by taking care of the entire data pipeline. With our technology, we instantly provide real-time reliable ESG data to our clients, saving up to millions of dollars in builging teams and processes internally to tackle these challenges.</div>
                    <div className="lynxai-vision-second-icons-div-mobile">
                        {<TransparentCarousel {...carousel_data} ></TransparentCarousel>}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default LynxaiVisionSecond;