import styled from "styled-components";
import background from "../images/background.png";

export const Container = styled.div`
  display: flex;
  flex-direction:column;
  flex-wrap: no-wrap;
  justify-content: center;
  align-items: center;
  height:100vh;
  margin: 0;
  overflow-x: hidden;
`;

export const InsideContainer = styled.div`
  width:100%;
  height: 100vh;
  display:flex;
  flex-flow:column;
  background-size: 100vw;
  background-repeat: repeat;
  background-image: linear-gradient( rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 1)), url(${background});
  @media (max-width: 1000px) {
  background-size: 150vw;
  }
  @media (max-width: 750px) {
    background-size: 200vw;
  }
  @media (max-width: 500px) {
    background-size: 1500px;
  }
  `

export const MainDiv = styled.div`
  flex-wrap: wrap;
  flex:1;
  padding: 20px;
  `;
