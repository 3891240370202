
import React from "react";

import { MainDiv } from "../../assets/styles/global";
import HomepageFirst from "../../components/HomepageFirst/HomepageFirst";
import HomepageSecond from "../../components/HomepageSecond/HomepageSecond";
import HomepageThird from "../../components/HomepageThird/HomepageThird";
import HomepageFourth from "../../components/HomepageFourth/HomepageFourth";
import HomepageFifth from "../../components/HomepageFifth/HomepageFifth";

export default function Home() {

  return (
    <MainDiv>
      <HomepageFirst></HomepageFirst>
      <HomepageSecond></HomepageSecond>
      <HomepageThird></HomepageThird>
      <HomepageFourth></HomepageFourth>
      <HomepageFifth></HomepageFifth>
    </MainDiv>
  );
}
