import React from 'react';

import ThreeReasons from '../ThreeReasons/ThreeReasons';

import "./ContactUsSecond.scss"

const email_us_icon = require('../../assets/images/email_us_icon.png');
const question_icon_black = require('../../assets/images/question_icon_black.png');
const pin_icon_black = require('../../assets/images/pin_icon_black.png');

const points = {
  moreHeight: "normal-height",
  first_icon: pin_icon_black,
  first_title: "Visit Us",
  first_text: `Praceta da Tabaqueira,\nPraceta B, Lote A, 5ºI, \n1950-256 Lisboa`,
  first_anchor: "https://www.google.com/maps/place/NKOOWOORK+%E2%80%93+Coworking+in+Lisbon/@38.7471046,-9.0998926,17.12z/data=!4m5!3m4!1s0xd19310300e68151:0xdd3b4acd9621e1a2!8m2!3d38.7468278!4d-9.0985088",
  second_icon: email_us_icon,
  second_title: "Email Us",
  second_text: "tiagofa@lynxai.tech",
  second_anchor: "mailto:tiagofa@lynxai.tech",
  third_icon: question_icon_black,
  third_title: "Message Us",
  third_text: "LinkedIn",
  third_anchor: "https://www.linkedin.com/company/lynxai/"
}

const ContactUsSecond = () => {
  return (
    <ThreeReasons {...points}></ThreeReasons>
  );
}
export default ContactUsSecond;
