import React from "react";


import TransparentCarousel from "../TransparentCarousel/TransparentCarousel"

import "./EUTaxonomySecond.scss"

const lynxai_rcr_image = require('../../assets/images/lynxai_rcr_image.png');
const homepage_2 = require('../../assets/images/homepage_2.png');
const recycle_icon_black = require('../../assets/images/recycle_icon_black.png');
const leaf_icon_black = require('../../assets/images/leaf_icon_black.png');
const globe_icon_black = require('../../assets/images/globe_icon_black.png');

const third_carousel_data = {
    carousel_class: "third-carousel",
    colors: {
        font_color: "black",
        border_color: "darkgray"
    },
    carousel_data: {
        1: {
            icon: recycle_icon_black,
            text: "EU Taxonomy is a classification system established to clarify which investments are environmentally sustainable, in the context of the European Green Deal."
        },
        2: {
            icon: leaf_icon_black,
            text: "It is playing an important role helping the EU scale up sustainable investment and implement the European green deal."
        },
        3: {
            icon: globe_icon_black,
            text: "For an economic activity be certified as being environmentally sustainable, substantial contribution must be made to at least one the six objectives and simultaneously have no significant detrimental impact on the other five."
        }
    }
}

const EUTaxonomySecond = () => {
    return (
        <div className="what-is-eu-taxonomy-div">
            <div className="what-is-eu-taxonomy-inside-div">
                <div className="what-is-eu-taxonomy-image-div">
                    <img className="eu-taxonomy-second-image-1" src={lynxai_rcr_image} alt="img"/>
                    <img className="eu-taxonomy-second-image-2" src={homepage_2} alt="img"/>
                </div>
                <div className="what-is-eu-taxonomy-info-div">
                    <div className="eu-taxonomy-second-title-desktop eu-taxonomy-second-title extra-large-text eu-taxonomy-second-title font-style-montserrat-family-regular color-black">What is EU Taxonomy?</div>
                    <div className="eu-taxonomy-second-icons-div-desktop">
                        <div className="eu-taxonomy-second-points">
                            <img className="eu-taxonomy-second-icon" src={recycle_icon_black} alt="img"/>
                            <div className="extra-small-text eu-taxonomy-second-text font-style-montserrat-family-regular color-lightgray">EU Taxonomy is a classification system established to clarify which investments are environmentally sustainable, in the context of the European Green Deal.</div>
                        </div>
                        <div className="eu-taxonomy-second-points">
                            <img className="eu-taxonomy-second-icon" src={leaf_icon_black} alt="img"/>
                            <div className="extra-small-text eu-taxonomy-second-text font-style-montserrat-family-regular color-lightgray">It is playing an important role helping the EU scale up sustainable investment and implement the European green deal.</div>
                        </div>
                    </div>
                    <div className="eu-taxonomy-second-third-point-desktop eu-taxonomy-second-third-point-desktop-last eu-taxonomy-second-points">
                        <img className="eu-taxonomy-second-icon" src={globe_icon_black} alt="img"/>
                        <div className="extra-small-text eu-taxonomy-second-text font-style-montserrat-family-regular color-lightgray">For an economic activity be certified as being environmentally sustainable, substantial contribution must be made to at least one the six objectives and simultaneously have no significant detrimental impact on the other five.</div>
                    </div>
                    <div className="eu-taxonomy-second-title-mobile eu-taxonomy-second-title extra-large-text font-style-montserrat-family-regular color-black">What is EU Taxonomy?</div>
                    <div className="eu-taxonomy-second-icons-div-mobile">
                        {<TransparentCarousel {...third_carousel_data} ></TransparentCarousel>}
                    </div>
                    
                </div>
            </div>
        </div>
    );
}

export default EUTaxonomySecond;