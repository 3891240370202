
import React from "react";

import { MainDiv } from "../../assets/styles/global";
import HomepageFifth from "../../components/HomepageFifth/HomepageFifth";
import EUTaxonomyFirst from "../../components/EUTaxonomyFirst/EUTaxonomyFirst";
import EUTaxonomySecond from "../../components/EUTaxonomySecond/EUTaxonomySecond";
import EUTaxonomyThird from "../../components/EUTaxonomyThird/EUTaxonomyThird";
import Citation from "../../components/Citation/Citation";
import ThreeReasons from "../../components/ThreeReasons/ThreeReasons";

const data_ml_ai_icon_black = require('../../assets/images/data_ml_ai_icon_black.png');
const search_icon_black = require('../../assets/images/search_icon_black.png');
const chart_icon_black = require('../../assets/images/chart_icon_black.png');

const points = {
  title: "What do we Deliver?",
  moreHeight: "more-height",
  first_icon: data_ml_ai_icon_black,
  first_title: "Interactive Platform",
  first_text: "We deliver our data through an online platform in real-time, where users can interact with our dashboards to understand the data and act on their portfolios.",
  second_icon: chart_icon_black,
  second_title: "Portfolio Performance Statistics",
  second_text: "We provide relevant statistics like the top performers in terms of eligibility and alignment.",
  third_icon: search_icon_black,
  third_title: "Intelligent Search Service",
  third_text: "We provide an intelligent search service where users can obtain all official information concerning EU Taxonomy regulations, so that they can easily find the content they’re looking for and discover the right answers to their questions."
}

export default function Home() {

  return (
    <MainDiv>
      <EUTaxonomyFirst></EUTaxonomyFirst>
      <EUTaxonomySecond></EUTaxonomySecond>
      <Citation text="The EU taxonomy requires information on how and to what extent the investments are invested in sustainable projects. Concerning financial market participants offering financial products."></Citation>
      <EUTaxonomyThird></EUTaxonomyThird>
      <ThreeReasons {...points}></ThreeReasons>
      <HomepageFifth></HomepageFifth>
    </MainDiv>
  );
}