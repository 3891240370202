import React from "react";

import "./ArticlesSecond.scss"

const clock_icon_black = require('../../assets/images/clock_icon_black.png');
const calendar_icon_black = require('../../assets/images/calendar_icon_black.png');
const small_article_1_img = require('../../assets/images/small_article_1.png');
const small_article_2_img = require('../../assets/images/small_article_2.png');
const small_article_3_img = require('../../assets/images/small_article_3.png');
const small_article_4_img = require('../../assets/images/small_article_4.png');
const small_article_5_img = require('../../assets/images/small_article_5.png');
const small_article_6_img = require('../../assets/images/small_article_6.png');
const small_article_7_img = require('../../assets/images/small_article_7.png');

const ArticlesSecond = (props) => {

    return (
        <div className="articles-second-main-div">
            <div className="articles-first-most-read-articles-title-div">
                <p className="articles-first-most-read-articles-title-text medium-text font-style-montserrat-family-regular color-black">All latest articles</p>
                <div className="articles-first-line-divider"></div>
            </div>
            <div className="articles-second-articles-div">
                <a href="https://www.linkedin.com/pulse/aggregate-confusion-divergence-esg-ratings-mit-paper-lynxai/" target="_blank">
                    <div className="articles-second-small-article-div">
                        <img className="articles-second-small-article-background" src={small_article_1_img} alt="img"/>
                        <div className="articles-second-small-article-info-div">
                            <p className="articles-second-small-article-title small-text font-style-montserrat-family-regular">The Divergence of ESG Ratings</p>
                            <p className="articles-second-small-article-description extra-small-text font-style-montserrat-family-regular color-black"> In 2008, the world saw the role that credit ratings play in one of the worst economic crises from the beginning of the millennium. Today, we also view this methodology on ESG. Well, MIT and their researchers explore this in a stunning paper with the title “Aggregate Confusion: The Divergence of ESG Ratings”. This paper demonstrated that ESG rating divergence is not merely a matter of varying definitions but a fundamental disagreement about the underlying data. Here are some interesting conclusions: “It is legitimate that different raters take different views on which categories are most important in ESG evaluation. A variety of opinions may be desirable given that the users of ESG ratings also have heterogeneous preferences for scope and weight. However, measurement divergence is problematic if one accepts the view that ESG ratings should ultimately be based on objective observations that can be ascertained.” </p>
                            <div className="articles-second-time-article-small-info">
                            <div className="articles-second-time-small-read-div">
                                <img className="articles-second-clock-icon" src={calendar_icon_black} alt="img"/>
                                <div className="articles-second-time extra-small-text font-style-montserrat-family-regular color-black">18 Oct, 2022</div>
                            </div>
                            <div className="articles-second-time-small-read-div">
                                <img className="articles-second-clock-icon" src={clock_icon_black} alt="img"/>
                                <div className="articles-second-time extra-small-text font-style-montserrat-family-regular color-black">2 min read</div>
                            </div>
                            </div>
                        </div>
                    </div>
                </a>
                <a href="https://www.linkedin.com/pulse/robert-kiyosaki-writer-bestselling-novel-rich-dad-poor-took-twitter-/" target="_blank">
                    <div className="articles-second-small-article-div">
                        <img className="articles-second-small-article-background" src={small_article_3_img} alt="img"/>
                        <div className="articles-second-small-article-info-div">
                            <p className="articles-second-small-article-title small-text font-style-montserrat-family-regular">Robert Kiyosaki Article</p>
                            <p className="articles-second-small-article-description extra-small-text font-style-montserrat-family-regular color-black">Robert Kiyosaki, the writer of bestselling personal finance book 'Rich Dad Poor Dad and financial influencer, took to Twitter to warn his followers of the impending global financial crisis and asked them to save food, Bitcoin, and precious metals. Kiyosaki highlighted that in the light of the World Economic Forum being conducted at Davos, Switzerland, it is clear that the world is on the brink of one of the greatest financial collapses since World War 2.</p>
                            <div className="articles-second-time-article-small-info">
                            <div className="articles-second-time-small-read-div">
                                <img className="articles-second-clock-icon" src={calendar_icon_black} alt="img"/>
                                <div className="articles-second-time extra-small-text font-style-montserrat-family-regular color-black">8 Aug, 2022</div>
                            </div>
                            <div className="articles-second-time-small-read-div">
                                <img className="articles-second-clock-icon" src={clock_icon_black} alt="img"/>
                                <div className="articles-second-time extra-small-text font-style-montserrat-family-regular color-black">4 min read</div>
                            </div>
                            </div>
                        </div>
                    </div>
                </a>
                <a href="https://techsense.lu/news/catapult-kickstarter-2022-summer-edition" target="_blank">
                    <div className="articles-second-small-article-div">
                        <img className="articles-second-small-article-background" src={small_article_5_img} alt="img"/>
                        <div className="articles-second-small-article-info-div">
                            <p className="articles-second-small-article-title small-text font-style-montserrat-family-regular">Catapult: Kickstarted 2022 Summer Edition</p>
                            <p className="articles-second-small-article-description extra-small-text font-style-montserrat-family-regular color-black">The third edition of the Fintech acceleration bootcamp, Catapult: Kickstarter 2022 Summer Edition, concluded on Friday 1st of July 2022 with the cohort’s final pitches in front of an esteemed jury and a public audience from the Luxembourg finance and technology ecosystem. The programme was developed by the LHoFT Foundation in close collaboration with the Ministry of the Economy in Luxembourg. Ten leading Fintech startups went through a two-weeks bootcamp. For the first time since the Catapult: Kickstarter launch in June 2021, the LHoFT was delighted to welcome the participants physically in Luxembourg.</p>
                            <div className="articles-second-time-article-small-info">
                            <div className="articles-second-time-small-read-div">
                                <img className="articles-second-clock-icon" src={calendar_icon_black} alt="img"/>
                                <div className="articles-second-time extra-small-text font-style-montserrat-family-regular color-black">14 July, 2022</div>
                            </div>
                            <div className="articles-second-time-small-read-div">
                                <img className="articles-second-clock-icon" src={clock_icon_black} alt="img"/>
                                <div className="articles-second-time extra-small-text font-style-montserrat-family-regular color-black">10 min read</div>
                            </div>
                            </div>
                        </div>
                    </div>
                </a>
                <a href="https://fundspeople.com/pt/a-plataforma-portuguesa-que-promete-simplificar-os-dados-esg-para-as-gestoras-de-ativos/" target="_blank">
                    <div className="articles-second-small-article-div">
                        <img className="articles-second-small-article-background" src={small_article_6_img} alt="img"/>
                        <div className="articles-second-small-article-info-div">
                            <p className="articles-second-small-article-title small-text font-style-montserrat-family-regular">FundsPeople Article</p>
                            <p className="articles-second-small-article-description extra-small-text font-style-montserrat-family-regular color-black">Como muitas outras entidades que se lançam num negócio novo, a RM Analytics viu no mercado uma lacuna (ou várias) que se compromete a colmatar. Na lista da Portugal Fintech de 2021 aparece então a startup liderada por Tiago Freire de Andrade, o CEO, e Eduardo Rebelo, o CTO, cujo objetivo é simples: simplificar os dados ESG fornecidos aos gestores de ativos. Em entrevista à FundsPeople, Tiago Freire Andrade começou por identificar isso mesmo. “O maior obstáculo encontrado no mercado é o acesso à informação tratada e imparcial, completamente ajustável às necessidades do gestor de ativos”, iniciou.</p>
                            <div className="articles-second-time-article-small-info">
                            <div className="articles-second-time-small-read-div">
                                <img className="articles-second-clock-icon" src={calendar_icon_black} alt="img"/>
                                <div className="articles-second-time extra-small-text font-style-montserrat-family-regular color-black">9 Dec, 2021</div>
                            </div>
                            <div className="articles-second-time-small-read-div">
                                <img className="articles-second-clock-icon" src={clock_icon_black} alt="img"/>
                                <div className="articles-second-time extra-small-text font-style-montserrat-family-regular color-black">7 min read</div>
                            </div>
                            </div>
                        </div>
                    </div>
                </a>
                <a href="https://www.linkedin.com/posts/luxembourg-trade-and-invest_esg-luxembourg-us-activity-6991385948357861376-tlEb?utm_source=share&utm_medium=member_desktop" target="_blank">
                    <div className="articles-second-small-article-div">
                        <img className="articles-second-small-article-background" src={small_article_4_img} alt="img"/>
                        <div className="articles-second-small-article-info-div">
                            <p className="articles-second-small-article-title small-text font-style-montserrat-family-regular">Lynxai #Fit4Start Article</p>
                            <p className="articles-second-small-article-description extra-small-text font-style-montserrat-family-regular color-black">#ESG portfolio investment specialist Lynxai has come to #Luxembourg from the #US to pitch for #startup accelerator #Fit4Start at Arch Summit. “#Luxembourg is the leading country in asset management in the world, creating the best possible environment for our #startup to find clients. With the creation of the Luxembourg #Green Exchange and the Luxembourg Sustainable Finance Initiative, it is also a leading market for #sustainable #investment. The #fintech ecosystem here is prospering in a way that we can’t see anywhere else.” – Eduardo Amaro Rebelo, CTO & Co-founder</p>
                            <div className="articles-second-time-article-small-info">
                            <div className="articles-second-time-small-read-div">
                                <img className="articles-second-clock-icon" src={calendar_icon_black} alt="img"/>
                                <div className="articles-second-time extra-small-text font-style-montserrat-family-regular color-black">2 Nov, 2022</div>
                            </div>
                            <div className="articles-second-time-small-read-div">
                                <img className="articles-second-clock-icon" src={clock_icon_black} alt="img"/>
                                <div className="articles-second-time extra-small-text font-style-montserrat-family-regular color-black">2 min read</div>
                            </div>
                            </div>
                        </div>
                    </div>
                </a>
                <a href="https://www.linkedin.com/pulse/1-esg-challenge-organizations-face-data-world-economic-forum-/" target="_blank">
                    <div className="articles-second-small-article-div">
                        <img className="articles-second-small-article-background" src={small_article_2_img} alt="img"/>
                        <div className="articles-second-small-article-info-div">
                            <p className="articles-second-small-article-title small-text font-style-montserrat-family-regular">ESG challenge organizations face</p>
                            <p className="articles-second-small-article-description extra-small-text font-style-montserrat-family-regular color-black">In October of 2021, Matt DiGuiseppe,Vice President of Research & ESG of World Economic Forum, wrote an article exposing some interesting points. Allow us to explore some of them! Just 9% of surveyed companies are actively using software that supports data collection, analysis and reporting on ESG. The foundations of a strong ESG program are built on data. Data-rich organizations operate more efficiently, more decisively and with greater foresight than their peers — and this is particularly true in a complex, evolving area like ESG.</p>
                            <div className="articles-second-time-article-small-info">
                            <div className="articles-second-time-small-read-div">
                                <img className="articles-second-clock-icon" src={calendar_icon_black} alt="img"/>
                                <div className="articles-second-time extra-small-text font-style-montserrat-family-regular color-black">26 Sep, 2022</div>
                            </div>
                            <div className="articles-second-time-small-read-div">
                                <img className="articles-second-clock-icon" src={clock_icon_black} alt="img"/>
                                <div className="articles-second-time extra-small-text font-style-montserrat-family-regular color-black">2 min read</div>
                            </div>
                            </div>
                        </div>
                    </div>
                </a>
                <a href="https://www.linkedin.com/posts/portugal-fintech_portugalfintechreport2022-portugalfintech-activity-6995680868790898688-9cPI/?utm_source=share&utm_medium=member_desktop" target="_blank">
                    <div className="articles-second-small-article-div">
                        <img className="articles-second-small-article-background" src={small_article_7_img} alt="img"/>
                        <div className="articles-second-small-article-info-div">
                            <p className="articles-second-small-article-title small-text font-style-montserrat-family-regular">Lynxai @ Portugal Fintech Report 2022</p>
                            <p className="articles-second-small-article-description extra-small-text font-style-montserrat-family-regular color-black">Take a look at the Portuguese Fintech Ecosystem! Portugal has a lot to offer as a hub of innovative solutions from Payments & Money Transfers to Lending & Credit, Wealth Management & ESG, Regtech & Cybersecurity, Real Estate, Insurtech and Blockchain & Crypto. Want to know more about the Portuguese Fintech Ecosystem? Download the Portugal Fintech Report 2022</p>
                            <div className="articles-second-time-article-small-info">
                            <div className="articles-second-time-small-read-div">
                                <img className="articles-second-clock-icon" src={calendar_icon_black} alt="img"/>
                                <div className="articles-second-time extra-small-text font-style-montserrat-family-regular color-black">7 Nov, 2022</div>
                            </div>
                            <div className="articles-second-time-small-read-div">
                                <img className="articles-second-clock-icon" src={clock_icon_black} alt="img"/>
                                <div className="articles-second-time extra-small-text font-style-montserrat-family-regular color-black">10 min read</div>
                            </div>
                            </div>
                        </div>
                    </div>
                </a>
            </div>
        </div>
    );
}

export default ArticlesSecond;