import styled from "styled-components";

export const StyledBurger = styled.div`
  width: 35px;
  height: 35px;
  margin-left: auto;
  margin-right: clamp(30px, 3vw, 60px);
  display: none;
  z-index:20;
  @media (max-width: 800px) {
    display: flex;
    justify-content: space-around;
    flex-flow: column nowrap;
  }
  div {
    width: 35px;
    height: 3px;
    background-color: ${({ open }) => open ? 'white' : '#333'};
    border-radius: 10px;
    transform-origin: 1px;
    transition: all 0.3s linear;
    &:nth-child(1) {
      transform: ${({ open }) => open ? 'rotate(45deg)' : 'rotate(0)'};
    }
    &:nth-child(2) {
      transform: ${({ open }) => open ? 'translateX(100%)' : 'translateX(0)'};
      opacity: ${({ open }) => open ? 0 : 1};
    }
    &:nth-child(3) {
      transform: ${({ open }) => open ? 'rotate(-45deg)' : 'rotate(0)'};
    }
  }

  @media (max-width: 500px) {
  height: 30px;
  div {
  width: 30px;
  height: 2.5px;
  }
}
  @media (max-width: 380px) {
    div {
    transform-origin: 0.5px;
    width:30px
    height: 2px;
      transform: ${({ open }) => open ? 'rotate(-50deg)' : 'rotate(0)'};
    }
    }
  }
`;

export const Ul = styled.ul`
  z-index:10;
  list-style: none;
  display: none;
  flex-flow: row nowrap;
  li {
    padding: 16px 20px;
    border-bottom: 1px solid var(--quill-gray);
  }
  @media (max-width: 800px) {
    flex-flow: column nowrap;
    display: flex;
    background-color: white;
    border-radius: 14px;
    position: fixed;
    transform: ${({ open }) => open ? 'translateX(0)' : 'translateX(115%)'};
    top: 100px;
    right: 30px;
    -webkit-padding: 0;
    -moz-padding: 0;
    padding:0;
    height: fit-content;
    width: 200px;
    transition: transform 0.3s ease-in-out;
    li {
    -webkit-margin: 0;
    -moz-margin: 0;
    margin:0;
    }
  }
`;

export function hide_background(rightMenuOpen) {
  const overlay = document.getElementById('overlay')
  if (rightMenuOpen) {
    overlay.style.display = 'none'
    document.body.style.overflow = "auto"
  } else {
    overlay.style.display = 'block'
    document.body.style.overflow = "hidden"
  }
}

export function darkenBackground(rightMenuOpen) {
  const overlay = document.getElementById('overlay')
  if (rightMenuOpen) {
    overlay.style.display = 'none'
    document.body.style.overflow = "auto"
  } else {
    overlay.style.display = 'block'
    document.body.style.overflow = "hidden"
  }
}