import React from 'react';

import RoundCornersMainImage from "../RoundCornersMainImage/RoundCornersMainImage";

import "./LynxaiVisionFirst.scss"

const lynxai_vision_background_image = require('../../assets/images/lynxai_vision_background_image.png');

const round_image_data = {
    background_image: lynxai_vision_background_image,
    text: "We provide billions of ESG data points"
}

const LynxaiVisionFirst = () =>{
  return (
      <RoundCornersMainImage {...round_image_data}></RoundCornersMainImage>
  );
}
export default LynxaiVisionFirst;
