import React from 'react';
import RequestDemoFirst from '../../components/RequestDemoFirst/RequestDemoFirst';
import { MainDiv } from "../../assets/styles/global";

const RequestDemo = () =>{
  return (
    <MainDiv>
      <RequestDemoFirst></RequestDemoFirst>
    </MainDiv>
  );
}
export default RequestDemo;
