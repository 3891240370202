import React from 'react';
import { MainDiv } from "../../assets/styles/global";

import WhyChooseLynxai from '../../components/WhyChooseLynxai/WhyChooseLynxai';
import HomepageFifth from "../../components/HomepageFifth/HomepageFifth";
import LynxaiVisionFirst from "../../components/LynxaiVisionFirst/LynxaiVisionFirst";
import LynxaiVisionSecond from "../../components/LynxaiVisionSecond/LynxaiVisionSecond";
import Citation from "../../components/Citation/Citation";

const LynxaiVision = () =>{
  return (
    <MainDiv>
      <LynxaiVisionFirst></LynxaiVisionFirst>
      <LynxaiVisionSecond></LynxaiVisionSecond>
      <Citation text="Lynxai Vision helps asset managers to have a vision of all relevant ESG data regarding their investments."></Citation>
      <WhyChooseLynxai></WhyChooseLynxai>
      <HomepageFifth></HomepageFifth>
    </MainDiv>
  );
}
export default LynxaiVision;
