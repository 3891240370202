import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronUp } from '@fortawesome/fontawesome-free-solid'

import "./scrollToTopButton.scss"

const ScrollToTopButton = () => {
  const main_container = document.getElementById("Container")
    return (
        <button onClick={() => { main_container.scrollTo({top: 0, left: 0, behavior: 'smooth'}); }} id="scroll-to-top-button"><FontAwesomeIcon icon={faChevronUp}></FontAwesomeIcon></button>
      );
    }
    
    
    export default ScrollToTopButton;
  