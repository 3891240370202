import React from "react";

import BlueTransparentButtonsDiv from "../BlueTransparentButtonsDiv/BlueTransparentButtonsDiv"

import "./HomepageFirst.scss"

const homepage_img_1 = require('../../assets/images/homepage_1.png');
const homepage_img_2 = require('../../assets/images/homepage_2.png');
const homepage_img_3 = require('../../assets/images/homepage_3.png');
const homepage_img_4 = require('../../assets/images/homepage_4.png');

const HomepageFirst = () => {
    return (
        <div className="block-div">
            <div className="sentence-buttons-div">
                <p className="sentence-paragraph font-style-montserrat-family-regular extra-extra-large-text color-black ">A data-driven platform that makes ESG investment more efficient</p>
                <BlueTransparentButtonsDiv flow="flow-row" colorTransparentButton="color-blue" transparentButtonText="Know More" fontSize="small-text"></BlueTransparentButtonsDiv>
            </div>
            <div className="homepage-images-div">
                <img id="homepage-img-1" src={homepage_img_1} alt="img"/>
                <img id="homepage-img-2" src={homepage_img_2} alt="img"/>
                <img id="homepage-img-3" src={homepage_img_3} alt="img"/>
                <img id="homepage-img-4" src={homepage_img_4} alt="img"/>
            </div>
        </div>
    );
}

export default HomepageFirst;