import React from 'react';
import SubmitedFormFirst from '../../components/SubmitedFormFirst/SubmitedFormFirst';
import { MainDiv } from "../../assets/styles/global";

const SubmitedForm = () =>{
  return (
    <MainDiv>
      <SubmitedFormFirst></SubmitedFormFirst>
    </MainDiv>
  );
}
export default SubmitedForm;
