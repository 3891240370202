import React from "react";
import { Link } from "react-router-dom";

import "./BlueButton.scss"

const BlueButton = (props) => {
  const { text, link, fitContent, component, fontSize } = props;

  return (
    <Link to={link}>
      <div className={`blue-button ${fontSize} ${component} ${fitContent} font-style-montserrat-family-regular color-white`}>{text}</div>
    </Link>
  );
}

export default BlueButton;