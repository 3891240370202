import React from "react";


import TransparentCarousel from "../TransparentCarousel/TransparentCarousel"

import "./PAIThird.scss"

const lynxai_rcr_image = require('../../assets/images/lynxai_rcr_image.png');
const homepage_2 = require('../../assets/images/homepage_2.png');
const recycle_icon_black = require('../../assets/images/recycle_icon_black.png');
const leaf_icon_black = require('../../assets/images/leaf_icon_black.png');
const globe_icon_black = require('../../assets/images/globe_icon_black.png');
const automatic_reporting_icon = require('../../assets/images/automatic_reporting_icon_black.png');

const third_carousel_data = {
    carousel_class: "third-carousel",
    colors: {
        font_color: "black",
        border_color: "darkgray"
    },
    carousel_data: {
        1: {
            icon: recycle_icon_black,
            text: "Principle Adverse Impact PAI is a disclosure requirement under the The Sustainable Finance Disclosure Regulation (SFDR)."
        },
        2: {
            icon: leaf_icon_black,
            text: "PAI refers to the negative impact that a company's activities have on sustainability factors."
        },
        3: {
            icon: globe_icon_black,
            text: "SFDR requires financial market participants to disclose information on how they integrate PAI considerations into their investment decision-making processes and the potential adverse impacts of their investments."
        },
        4: {
            icon: automatic_reporting_icon,
            text: "Automated reporting solutions like Lynxai can help investors simplify the complex and challenging process of collecting, analyzing, and comparing PAI data across companies and industries."
        }
    }
}

const PAIThird = () => {
    return (
        <div className="what-is-eu-taxonomy-div">
            <div className="what-is-eu-taxonomy-inside-div">
                <div className="what-is-eu-taxonomy-image-div">
                    <img className="pai-second-image-1" src={lynxai_rcr_image} alt="img"/>
                    <img className="pai-second-image-2" src={homepage_2} alt="img"/>
                </div>
                <div className="what-is-eu-taxonomy-info-div">
                    <div className="pai-second-title-desktop pai-second-title extra-large-text pai-second-title font-style-montserrat-family-regular color-black">What is SFDR PAI?</div>
                    <div className="pai-second-icons-div-desktop">
                        <div className="pai-second-points">
                            <img className="pai-second-icon" src={recycle_icon_black} alt="img"/>
                            <div className="extra-small-text pai-second-text font-style-montserrat-family-regular color-lightgray">Principle Adverse Impact PAI is a disclosure requirement under the The Sustainable Finance Disclosure Regulation (SFDR).</div>
                        </div>
                        <div className="pai-second-points">
                            <img className="pai-second-icon" src={leaf_icon_black} alt="img"/>
                            <div className="extra-small-text pai-second-text font-style-montserrat-family-regular color-lightgray">PAI refers to the negative impact that a company's activities have on sustainability factors.</div>
                        </div>
                    </div>
                    <div className="pai-second-icons-div-desktop">
                        <div className="pai-second-points">
                            <img className="pai-second-icon" src={globe_icon_black} alt="img"/>
                            <div className="extra-small-text pai-second-text font-style-montserrat-family-regular color-lightgray">SFDR requires financial market participants to disclose information on how they integrate PAI considerations into their investment decision-making processes and the potential adverse impacts of their investments.</div>
                        </div>
                        <div className="pai-second-points">
                            <img className="pai-second-icon" src={automatic_reporting_icon} alt="img"/>
                            <div className="extra-small-text pai-second-text font-style-montserrat-family-regular color-lightgray">Automated reporting solutions like Lynxai can help investors simplify the complex and challenging process of collecting, analyzing, and comparing PAI data across companies and industries.</div>
                        </div>
                    </div>
                    <div className="pai-second-title-mobile pai-second-title extra-large-text font-style-montserrat-family-regular color-black">What is SFDR PAI?</div>
                    <div className="pai-second-icons-div-mobile">
                        {<TransparentCarousel {...third_carousel_data} ></TransparentCarousel>}
                    </div>
                    
                </div>
            </div>
        </div>
    );
}

export default PAIThird;