import React from 'react';
import ReactDOM from 'react-dom/client';
import "./assets/styles/global.scss"
import App from './App';
import { BrowserRouter as Router } from "react-router-dom";

import * as AWS from 'aws-sdk'

AWS.config.update({
  region: 'eu-west-2',
  secretAccessKey: 'Puf8xsJyFqGRrqDdv/xRIZWVJOxf6xe2yIv80a54',
  accessKeyId: 'AKIAWXL7KXKEEYT34XW3'
})

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <Router>
      <App/>
    </Router>
  </React.StrictMode>
);
