import React from 'react';

import "./TeamMemberDetails.scss";

const linkedin_icon = require('../../assets/images/linkedin-logo-white.png');
const bullet_point = require('../../assets/images/bullet_point_gray.png');

const TeamMemberDetails = (props) => {
  const { photo, name, linkedinLink, position, experience } = props;

    return (
        <div className="team-member-details">
            <img className="team-member-img" src={photo} alt="img"></img>
            <div className="team-member-text-div">
                <div className="team-member-name-linkedin">
                    <p className="small-text font-style-montserrat-family-regular color-white">{name}</p>
                    <a href={linkedinLink} target="_blank"><div className="about-us-fourth-linkedin-button"><img className="about-us-fourth-linkedin-image" src={linkedin_icon} alt="img"></img></div></a>
                </div>
                <p className="about-us-fourth-team-member-experience extra-small-text font-style-montserrat-family-regular color-blue">{position}</p>
                <div className="about-us-fourth-experience-main-div">
                {Object.keys(experience).map((key, index) => (
                     <div key={index} className="about-us-fourth-experience-div">
                     <img className="about-us-fourth-experience-bullet" src={bullet_point} alt="img"></img>
                     <p className="about-us-fourth-experience-text extra-small-text font-style-montserrat-family-regular color-lightgray">{experience[key]}</p>
                 </div>
                ))}
                </div>
            </div>
        </div>
    );
}
export default TeamMemberDetails;