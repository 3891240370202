import React from "react";

import "./RoundCornersMainImage.scss"

import RoundCornersImageContent from "../RoundCornersImageContent/RoundCornersImageContent"

const RoundCornersMainImage = (props) => {
  const { background_image, text, showNoShow } = props;

    return (
        <div className="round-corners-main-image-block-div">
            <img className="round-corners-main-image-block-img" src={background_image} alt="img"/>
            <div className="round-corners-main-image-block-darker"></div>
            <RoundCornersImageContent text={text} showNoShow={showNoShow}></RoundCornersImageContent>
        </div>
    );
}

export default RoundCornersMainImage;