import React from 'react';
import "./SubmitedFormFirst.scss";

import BlueButton from "../BlueButton/BlueButton"

const SubmitedFormFirst = () => {
  return (
    <div className="submitted-form-div">
      <div className="submitted-form-text-div">
        <h1 className="extra-large-text contact-us-first-title font-style-montserrat-family-regular color-black">Thank you</h1>
        <div className="small-text font-style-montserrat-family-regular color-blue">Form submitted. We will be in touch soon.</div>
      </div>
      <BlueButton link="/" text="Back to Homepage" fitContent="fit-content" fontSize="exztra-small-text"></BlueButton>
    </div>
  );
}
export default SubmitedFormFirst;
