import React from "react";
import { Pagination, Autoplay } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

import "./TransparentThreeRowsCarousel.scss"

import 'swiper/scss';
import 'swiper/scss/pagination';

const TransparentThreeRowsCarousel = (props) => {
    const { carousel_class, carousel_data, colors } = props;
    return (
        <div className="main-transparent-three-rows-carousel-div">
            <Swiper modules={[Autoplay, Pagination]}
                pagination={{
                    el: `.transparent-three-rows-carousel-pagination-div-${carousel_class}`,
                    clickable: true,
                    renderBullet: (index, className) => {
                        return '<span class="' + className + '"></span>';
                    },
                }} loop={true} autoplay={{ delay: 4000, disableOnInteraction: false }} speed={300} centeredSlides={true} slidesPerView="1.4" spaceBetween={20}>
                {Object.keys(carousel_data).map((key, index) => (
                <SwiperSlide key={index}>
                <div className={`transparent-three-rows-carousel-point border-top-${colors.border_color}`}>
                    <img className="homepage-second-icon" src={carousel_data[key].icon} alt="img"/>
                    <div className={`medium-text transparent-carousel-text font-style-montserrat-family-semibold color-${colors.font_color}`}>{carousel_data[key].title}</div>
                    <div className={`small-text transparent-carousel-text font-style-montserrat-family-regular color-${colors.font_color}`}>{carousel_data[key].text}</div>
                </div>
            </SwiperSlide>
                ))}
            </Swiper>
            <div className={`transparent-three-rows-carousel-pagination-div-${carousel_class}`} />
        </div>
    );
}

export default TransparentThreeRowsCarousel;