
import React from 'react';
import MentorCarousel from '../MentorCarousel/MentorCarousel';
import MentorDetailsDesktop from '../MentorDetailsDesktop/MentorDetailsDesktop';

import "./AboutUsFifth.scss";

const lynxai_logo_black = require('../../assets/images/lynxai_logo_black.png');


const matt_vail = require('../../assets/images/matt_vail.png');
const experience_matt_vail = {
    1: "Chief of Staff at Songkick",
    2: "VP of Business Development at Songkick",
    3: "VP of sales at Lyte"
}
const martin_giese = require('../../assets/images/martin_giese.png');
const experience_martin_giese = {
    1: 'Author of Book "Fast Forward - B2B Sales for Startups"',
    2: "Managing Director and Owner at Cambridge Ventures UG",
    3: "Managing Director at XPRENEURS incubator"
}
const maria_manuel = require('../../assets/images/maria_manuel.png');
const experience_maria_manuel = {
    1: "Member of Audit Board & Member of Risk Committee at SIBS",
    2: "Member of the Supervisory board at OutSystems",
    3: "Harvard graduation on Corporate Governance"
}
const christoper_pardo = require('../../assets/images/christoper_pardo.png');
const experience_christoper_pardo = {
    1: "Ex VP of Innovation at D&B",
    2: "Ex IBM",
    3: "Founding member at ECN"
}
const gerrit_mcgowan = require('../../assets/images/gerrit_mcgowan.png');
const experience_gerrit_mcgowan = {
    1: "Investor at Brainfood Ventures",
    2: 'Founder and Host at "the Most Awesome Founder Podcast"',
    3: "Founder at WHU Accelerator",
    4: "​Founder at Kula.com"
}
const joao_ascensao = require('../../assets/images/joao_ascensao.png');
const experience_joao_ascensao = {
    1: "Founder LP at Shilling",
    2: "Senior Manager at Farfetch",
    3: "Head of Data at Uniplaces",
    4: "Senior Market Research Analyst at ComparaJá"
}

const mentors_data_mobile = {
    mentors_info: {
        1: {
            name: "Matt Vail",
            photo: matt_vail,
            linkedinLink: "https://www.linkedin.com/in/matt-vail/",
            location: "California, USA",
            position: "AWS Startups",
            experience: {
                1: "Chief of Staff at Songkick",
                2: "VP of Business Development at Songkick",
                3: "VP of sales at Lyte"
            }
        },
        2: {
            name: "Martin Giese",
            photo: martin_giese,
            linkedinLink: "https://www.linkedin.com/in/martin-giese/",
            location: "Munich, Germany",
            position: "Startup Coach and Investor",
            experience: {
                1: 'Author of Book "Fast Forward - B2B Sales for Startups"',
                2: "Managing Director and Owner at Cambridge Ventures UG",
                3: "Managing Director at XPRENEURS incubator"
            }
        },
        3: {
            name: "Maria Manuel Seabra",
            photo: maria_manuel,
            linkedinLink: "https://www.linkedin.com/in/mmseabracosta/",
            location: "Lisbon, Portugal",
            position: "Member of Audit Board at CGD",
            experience: {
                1: "Member of Audit Board & Member of Risk Committee at SIBS",
                2: "Member of the Supervisory board at OutSystems",
                3: "Harvard graduation on Corporate Governance"
            }
        },
        4: {
            name: "Christoper Pardo",
            photo: christoper_pardo,
            linkedinLink: "https://www.linkedin.com/in/christopherpardo/",
            location: "Texas, USA",
            position: "Chief Data Office at apexanalytix",
            experience: {
                1: "Ex VP of Innovation at D&B",
                2: "Ex IBM",
                3: "Founding member at ECN"
            }
        },
        5: {
            name: "Gerrit McGowan",
            photo: gerrit_mcgowan,
            linkedinLink: "https://www.linkedin.com/in/gerritmcgowan/",
            location: "Berlin, Germany",
            position: "CEO Rinmaking Venture Studios",
            experience: {
                1: "Investor at Brainfood Ventures",
                2: 'Founder and Host at "the Most Awesome Founder Podcast"',
                3: "Founder at WHU Accelerator",
                4: "​Founder at Kula.com"
            }
        },
        6: {
            name: "João Tiago Ascensão",
            photo: joao_ascensao,
            linkedinLink: "https://www.linkedin.com/in/jtascensao/",
            location: "Lisbon, Portugal",
            position: "Senior Director of Research, AI at Feedzai",
            experience: {
                1: "Founder LP at Shilling",
                2: "Senior Manager at Farfetch",
                3: "Head of Data at Uniplaces",
                4: "Senior Market Research Analyst at ComparaJá"
            }
        }
    }

}

const AboutUsFifth = () => {
    return (
        <div className="about-us-fifth-main-div">
            <img className="about-us-second-icon" src={lynxai_logo_black} alt="img"/>
            <p className="three-reasons-title extra-large-text  font-style-montserrat-family-regular color-black">Our Mentors</p>
            <p className="about-us-second-text medium-text font-style-montserrat-family-regular color-black">Our mentors are part of our team. Their experience is the key to our development.</p>
            <div className="about-us-fifth-mentors-div-desktop about-us-fifth-mentors-div">
                < MentorDetailsDesktop name="Matt Vail" location="California, USA" position="AWS Startups" photo={matt_vail} linkedinLink="https://www.linkedin.com/in/matt-vail/" experience={experience_matt_vail} ></MentorDetailsDesktop>
                < MentorDetailsDesktop name="Martin Giese" location="Munich, Germany" position="Startup Coach and Investor" photo={martin_giese} linkedinLink="https://www.linkedin.com/in/martin-giese/" experience={experience_martin_giese} ></MentorDetailsDesktop>
                < MentorDetailsDesktop name="Maria Manuel Seabra" location="Lisbon, Portugal" position="Member of Audit Board at CGD" photo={maria_manuel} linkedinLink="https://www.linkedin.com/in/mmseabracosta/" experience={experience_maria_manuel} ></MentorDetailsDesktop>
                < MentorDetailsDesktop name="Christoper Pardo" location="Texas, USA" position="Chief Data Office at apexanalytix" photo={christoper_pardo} linkedinLink="https://www.linkedin.com/in/christopherpardo/" experience={experience_christoper_pardo} ></MentorDetailsDesktop>
                < MentorDetailsDesktop name="Gerrit McGowan" location="Berlin, Germany" position="CEO Rinmaking Venture Studios" photo={gerrit_mcgowan} linkedinLink="https://www.linkedin.com/in/gerritmcgowan/" experience={experience_gerrit_mcgowan} ></MentorDetailsDesktop>
                < MentorDetailsDesktop name="João Tiago Ascensão" location="Lisbon, Portugal" position="Senior Director of Research, AI at Feedzai" photo={joao_ascensao} linkedinLink="https://www.linkedin.com/in/jtascensao/" experience={experience_joao_ascensao} ></MentorDetailsDesktop>
            </div>
            <div className="about-us-fifth-mentors-div-mobile about-us-fifth-mentors-div">
                <MentorCarousel {...mentors_data_mobile}></MentorCarousel>
            </div>
        </div>
    );
}
export default AboutUsFifth;

