import React from "react";

import "./RoundCornersImageContent.scss"

import BlueTransparentButtonsDiv from "../BlueTransparentButtonsDiv/BlueTransparentButtonsDiv"

const lynxai_logo_white = require('../../assets/images/lynxai_logo_white.png');

const RoundCornersImageContent = (props) => {
    const { text, showNoShow } = props;

    return (
        <div className="round-corners-main-image-block-content">
            <img className="lynxai-logo" src={lynxai_logo_white}  alt="img"/>
            <p className="round-corners-main-image-main-text extra-large-text font-style-montserrat-family-regular color-white">{text}</p>
            <div className={showNoShow}><BlueTransparentButtonsDiv flow="flow-row" colorTransparentButton="color-white" transparentButtonText="Talk with Us" fontSize="small-text"></BlueTransparentButtonsDiv></div>
        </div>
    );
}

export default RoundCornersImageContent;