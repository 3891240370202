import React from "react";

import WhyChooseLynxai from "../WhyChooseLynxai/WhyChooseLynxai";

import "./HomepageThird.scss"

import BlueButton from "../BlueButton/BlueButton"

const dashboard_img = require('../../assets/images/dashboard_img.png');

const HomepageThird = () => {
    return (
        <div className="homepage-third-block-div">
            <div className="homepage-third-first-row">
                <div className="homepage-third-dashboard-text">
                    <p className="homepage-third-dashboard-text-first-sentence small-text font-style-montserrat-family-regular color-black">We provide interactive dashboards to facilitate investment analysis and see changes take effect in real-time.</p>
                    <p className="homepage-third-dashboard-text-first-sentence small-text font-style-montserrat-family-regular color-black margin-top-sentence">Understand all the data provided with an intelligent search service that answers queries with automatically generated data visualizations.</p>
                    <div className="blue-button-div"><BlueButton link="/request-demo" text="Try a Demo" fitContent="fixed-width" fontSize="small-text"></BlueButton></div>
                </div>
                <div className="dashboard-div">
                    <div className="dashboard-img" style={{ backgroundImage: `url(${dashboard_img})` }}></div>
                </div>
            </div>
            <WhyChooseLynxai></WhyChooseLynxai>
        </div>
    );
}

export default HomepageThird;