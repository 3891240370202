import React from "react";

import "./EUTaxonomyThird.scss"

import BlueButton from "../BlueButton/BlueButton"
import TransparentThreeRowsCarousel from "../TransparentThreeRowsCarousel/TransparentThreeRowsCarousel"

const lynxai_rcr_diagram = require('../../assets/images/lynxai_rcr_diagram.png');
const automatic_reporting_icon = require('../../assets/images/automatic_reporting_icon.png');
const identify_liabilities_icon = require('../../assets/images/identify_liabilities_icon.png');
const guarantee_compliance_icon = require('../../assets/images/guarantee_compliance_icon.png');
const real_time_icon_white = require('../../assets/images/real_time_icon_white.png');
const cloud_icon_white = require('../../assets/images/cloud_icon_white.png');

const first_carousel_data = {
    carousel_class: "first-carousel",
    colors: {
        font_color: "white",
        border_color: "lightgray"
    },
    carousel_data: {
        1: {
            icon: automatic_reporting_icon,
            title: "Efficient EU Taxonomy Reporting",
            text: "Generate automatic reports to present to clients, regulators, etc."
        },
        2: {
            icon: identify_liabilities_icon,
            title: "Control of investments eligibility and alignment",
            text: "Clear dashboards to understand all relevant metrics that relate to good performance or liabilities in an investment portfolio."
        },
        3: {
            icon: guarantee_compliance_icon,
            title: "Centralized data from EU Taxonomy",
            text: "Automatized data extraction of all the relevant metrics from company reports."
        },
        4: {
            icon: real_time_icon_white,
            title: "Real-Time Data",
            text: "Updated data that guarantees reliability and accuracy, see the changes you make taking effect in real time."
        },
        5: {
            icon: cloud_icon_white,
            title: "Intelligent Search Service",
            text: "We provide an intelligent search service where users can obtain all official information concerning EU Taxonomy regulations, so that they can easily find the content they’re looking for and discover the right answers to their questions."
        }
    }
}

const EUTaxonomyThird = () => {
    return (
        <div className="eu-taxonomy-third-block-div">
            <div className="eu-taxonomy-div">
                <div className="eu-taxonomy-third-main-title-mobile extra-large-text eu-taxonomy-third-title font-style-montserrat-family-regular color-white">Do you need help with your EU Taxonomy?</div>
                <div className="eu-taxonomy-inside-div">
                    <div className="eu-taxonomy-third-info-div">
                        <div className="eu-taxonomy-third-main-title-desktop extra-large-text eu-taxonomy-third-title font-style-montserrat-family-regular color-white">Do you need help with your EU Taxonomy?</div>
                        <div className="eu-taxonomy-third-icons-div-desktop">
                            <div className="eu-taxonomy-third-points">
                                <img className="eu-taxonomy-third-icon" src={automatic_reporting_icon} alt="img"/>
                                <div className="small-text eu-taxonomy-third-text-desktop font-style-montserrat-family-regular color-white">Efficient EU Taxonomy Reporting</div>
                                <div className="extra-extra-small-text eu-taxonomy-third-text-desktop font-style-montserrat-family-regular color-white">Generate automatic reports to present to clients, regulators, etc.</div>
                            </div>
                            <div className="eu-taxonomy-third-points">
                                <img className="eu-taxonomy-third-icon" src={identify_liabilities_icon} alt="img"/>
                                <div className="small-text eu-taxonomy-third-text-desktop font-style-montserrat-family-regular color-white">Control of investments eligibility and alignment</div>
                                <div className="extra-extra-small-text eu-taxonomy-third-text-desktop font-style-montserrat-family-regular color-white">Clear dashboards to understand all relevant metrics that relate to good performance or liabilities in an investment portfolio.</div>
                            </div>
                            <div className="eu-taxonomy-third-points">
                                <img className="eu-taxonomy-third-icon" src={guarantee_compliance_icon} alt="img"/>
                                <div className="small-text eu-taxonomy-third-text-desktop font-style-montserrat-family-regular color-white">Centralized data from EU Taxonomy</div>
                                <div className="extra-extra-small-text eu-taxonomy-third-text-desktop font-style-montserrat-family-regular color-white">Automatized data extraction of all the relevant metrics from company reports.</div>
                            </div>
                            <div className="eu-taxonomy-third-points">
                                <img className="eu-taxonomy-third-icon" src={real_time_icon_white} alt="img"/>
                                <div className="small-text eu-taxonomy-third-text-desktop font-style-montserrat-family-regular color-white">Real-Time Data</div>
                                <div className="extra-extra-small-text eu-taxonomy-third-text-desktop font-style-montserrat-family-regular color-white">Updated data that guarantees reliability and accuracy, see the changes you make taking effect in real time.</div>
                            </div>
                        </div>
                        <div className="eu-taxonomy-third-fifth-point-desktop eu-taxonomy-third-points">
                            <img className="eu-taxonomy-third-icon" src={cloud_icon_white} alt="img"/>
                            <div className="small-text eu-taxonomy-third-text-desktop font-style-montserrat-family-regular color-white">Intelligent Search Service</div>
                            <div className="extra-extra-small-text eu-taxonomy-third-text-desktop font-style-montserrat-family-regular color-white">We provide an intelligent search service where users can obtain all official information concerning EU Taxonomy regulations, so that they can easily find the content they’re looking for and discover the right answers to their questions.</div>
                        </div>
                        <div className="eu-taxonomy-third-icons-div-mobile">
                            <TransparentThreeRowsCarousel {...first_carousel_data} ></TransparentThreeRowsCarousel>
                        </div>
                        <div className="button-div">
                            <BlueButton text="Know More" fitContent="fixed-width" fontSize="small-text"></BlueButton>
                        </div>
                    </div>
                    <div className="eu-taxonomy-third-image-div">
                        <img className="image-diagram" src={lynxai_rcr_diagram} alt="img"/>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default EUTaxonomyThird;