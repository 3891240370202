

import React from 'react';

import FormMessage from '../FormMessage/FormMessage';

import "./RequestDemoFirst.scss"

const RequestDemoFirst = (props) => {

    return (
        <div className="request-demo-div">
            <div className="request-demo-first-text-div">
                <div className="medium-text font-style-montserrat-family-regular color-blue">Request a Demo</div>
                <h1 className="extra-extra-large-text contact-us-first-title font-style-montserrat-family-regular color-black">Tell Us Why</h1>
            </div>
            <FormMessage messagePlaceholder="Why do you want a Demo?*" mailTemplate="try_a_demo_request"></FormMessage>
        </div>
    );
}

export default RequestDemoFirst;