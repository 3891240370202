import React from "react";

import "./Citation.scss"

import BlueTransparentButtonsDiv from "../BlueTransparentButtonsDiv/BlueTransparentButtonsDiv"

const citation_icon = require('../../assets/images/citation_icon.png');

const Citation = (props) => {
    const { text } = props;

    return (
        <div className="citation-div">
            <img className="citation-image" src={citation_icon} alt="img"></img>
            <div className="citation-text extra-large-text  font-style-montserrat-family-regular color-black">{text}</div>
            <BlueTransparentButtonsDiv flow="flow-row-inverse" colorTransparentButton="color-blue" transparentButtonText="Talk with Us" fontSize="small-text"></BlueTransparentButtonsDiv>
        </div>
    );
}

export default Citation;