import React from 'react';
import { MainDiv } from "../../assets/styles/global";

import LynxaiRCRFirst from "../../components/LynxaiRCRFirst/LynxaiRCRFirst";
import LynxaiRCRSecond from "../../components/LynxaiRCRSecond/LynxaiRCRSecond";
import HomepageFifth from "../../components/HomepageFifth/HomepageFifth";
import WhyChooseLynxai from '../../components/WhyChooseLynxai/WhyChooseLynxai';

const LynxaiRcr = () =>{
  return (
    <MainDiv>
      <LynxaiRCRFirst></LynxaiRCRFirst>
      <LynxaiRCRSecond></LynxaiRCRSecond>
      <WhyChooseLynxai></WhyChooseLynxai>
      <HomepageFifth></HomepageFifth>
    </MainDiv>
  );
}
export default LynxaiRcr;
