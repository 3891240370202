import "./App.scss"
import React from "react";
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

import { Container, InsideContainer } from "./assets/styles/global";
import ScrollToTopButton from "./utils/scrollToTop/scrollToTopButton";
import ScrollToTopFunction from "./utils/scrollToTop/scrollToTopFunction";

import { Route, Routes } from "react-router-dom";
import Navbar from "./components/Navbar/Navbar"
import Footer from "./components/Footer/Footer"
import Home from "./views/Home/Home"
import LynxaiRcr from "./views/LynxaiRCR/LynxaiRCR"
import EUTaxonomy from "./views/EUTaxonomy/EUTaxonomy"
import PAI from "./views/PAI/PAI"
import LynxaiVision from "./views/LynxaiVision/LynxaiVision"
import AboutUs from "./views/AboutUs/AboutUs"
import Articles from "./views/Articles/Articles"
import ContactUs from "./views/ContactUs/ContactUs"
import RequestDemo from "./views/RequestDemo/RequestDemo"
import SubmittedForm from "./views/SubmitedForm/SubmitedForm"
import PageNotFound from "./views/PageNotFound/PageNotFound";
import MainLoader from "./components/MainLoader/MainLoader";

function App() {
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (document.readyState === 'complete') {
      hideLoader();
    } else {
      window.addEventListener('load', hideLoader);
      return () => window.removeEventListener('load', hideLoader);
    }
  }, []);

  const listenToScroll = () => {
    const winScroll = document.getElementById("Container").scrollTop
    if (winScroll > 150) {
      const button_el = document.getElementById("scroll-to-top-button")
      button_el.style.visibility = "visible"
      button_el.style.opacity = "1"
    } else {
      const button_el = document.getElementById("scroll-to-top-button")
      button_el.style.visibility = "collapse"
      button_el.style.opacity = "0"
    }
  };

  const hideLoader = () => {
    setTimeout(() => {
      setIsLoading(false)
    }, 1000);
  }

  const showLoader = () => {
    setIsLoading(true)
    setTimeout(() => {
      setIsLoading(false)
    }, 1000);
  }

  let location = useLocation();
  useEffect(() => {
    showLoader()
  }, [location]);

  return (
    <Container id="Container" onScroll={listenToScroll}>
      {isLoading ? (
        <MainLoader>
        </MainLoader>
      ) : (
        <>
          <InsideContainer>
          <ScrollToTopButton></ScrollToTopButton>
            <ScrollToTopFunction>
              <Navbar />
              <Routes>
                <Route exact path='/' element={<Home />} />
                <Route exact path='/lynxai-rcr' element={<LynxaiRcr />} />
                <Route exact path='/eu-taxonomy' element={<EUTaxonomy />} />
                <Route exact path='/pai' element={<PAI />} />
                <Route exact path='/lynxai-vision' element={<LynxaiVision />} />
                <Route exact path='/about-us' element={<AboutUs />} />
                <Route exact path='/articles' element={<Articles />} />
                <Route exact path='/contact-us' element={<ContactUs />} />
                <Route exact path='/request-demo' element={<RequestDemo />} />
                <Route exact path='/submitted-form' element={<SubmittedForm />} />
                <Route path="*" element={<PageNotFound />} />
              </Routes>
              <Footer />
            </ScrollToTopFunction>
          </InsideContainer></>)}
    </Container>
  );
}

export default App;
