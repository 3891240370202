import React from "react";

import "./ContactUsMap.scss"

const ContactUsMap = (props) => {

    return (
        <div className="map-div">
            <iframe title="map" id="gmap_canvas-mobile" src="https://maps.google.com/maps?q=Praceta%20da%20Tabaqueira,%20Praceta%20B,%20Lote%20A,%205%C2%BAI,%201950-256%20Lisboa&amp;t=&amp;z=16&amp;ie=UTF8&amp;iwloc=&amp;output=embed"></iframe>
            <iframe title="map" id="gmap_canvas-tablet" src="https://maps.google.com/maps?q=Praceta%20da%20Tabaqueira,%20Praceta%20B,%20Lote%20A,%205%C2%BAI,%201950-256%20Lisboa&amp;t=&amp;z=17&amp;ie=UTF8&amp;iwloc=&amp;output=embed"></iframe>
            <iframe title="map" id="gmap_canvas-desktop" src="https://maps.google.com/maps?q=Praceta%20da%20Tabaqueira,%20Praceta%20B,%20Lote%20A,%205%C2%BAI,%201950-256%20Lisboa&amp;t=&amp;z=17&amp;ie=UTF8&amp;iwloc=&amp;output=embed"></iframe>
        </div>
    );
}

export default ContactUsMap;