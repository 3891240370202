import React from "react";

import "./HomepageSecond.scss"

import BlueButton from "../BlueButton/BlueButton"
import TransparentButton from "../TransparentButton/TransparentButton";
import TransparentCarousel from "../TransparentCarousel/TransparentCarousel"

const lynxai_rcr_diagram = require('../../assets/images/lynxai_rcr_diagram.png');
const automatic_reporting_icon = require('../../assets/images/automatic_reporting_icon.png');
const identify_liabilities_icon = require('../../assets/images/identify_liabilities_icon.png');
const guarantee_compliance_icon = require('../../assets/images/guarantee_compliance_icon.png');
const real_time_icon_white = require('../../assets/images/real_time_icon_white.png');
const data_collection_icon_white = require('../../assets/images/data_collection_icon_white.png');
const objetive_data_icon = require('../../assets/images/objetive_data_icon.png');
const data_ml_ai_icon_white = require('../../assets/images/data_ml_ai_icon_white.png');
const social_media_trends_icon = require('../../assets/images/social_media_trends_icon.png');
const lynxai_vision_diagram = require('../../assets/images/companies_stock.png');

const first_carousel_data = {
    carousel_class: "first-carousel",
    colors: {
        font_color: "white",
        border_color: "lightgray"
    },
    carousel_data: {
        1: {
            icon: automatic_reporting_icon,
            text: "Automatic Reporting"
        },
        2: {
            icon: identify_liabilities_icon,
            text: "Identification of regulatory liabilities in investment portfolios"
        },
        3: {
            icon: guarantee_compliance_icon,
            text: "Guaranteed regulatory compliance"
        },
        4: {
            icon: real_time_icon_white,
            text: "Modify portfolios in real-time to  meet regulatory goals"
        }
    }
}

const second_carousel_data = {
    carousel_class: "second-carousel",
    colors: {
        font_color: "white",
        border_color: "lightgray"
    },
    carousel_data: {
        1: {
            icon: data_collection_icon_white,
            text: "Public data collection from social media, news, and reports"
        },
        2: {
            icon: objetive_data_icon,
            text: "Objective data, no subjective and opaque ratings, and scores"
        },
        3: {
            icon: social_media_trends_icon,
            text: "Social media trend and red flag monitoring"
        },
        4: {
            icon: data_ml_ai_icon_white,
            text: "Data handled with ML and AI technologies to draw insights and statistics"
        }
    }
}

const HomepageSecond = () => {
    return (
        <div className="homepage-second-block-div">
            <div className="lynxai-rcr-div">
                <div className="homepage-second-main-title-mobile extra-large-text homepage-second-title font-style-montserrat-family-regular color-white">Lynxai RCR</div>
                <p className="homepage-second-main-title-mobile homepage-second-description small-text font-style-montserrat-family-regular color-white">Lynxai RCR focuses on helping asset managers report their investments and comply with regulations, effortlessly.</p>
                <div className="lynxai-rcr-inside-div">
                    <div className="homepage-second-info-div">
                        <div className="homepage-second-main-title-desktop extra-large-text homepage-second-title font-style-montserrat-family-regular color-white">Lynxai RCR</div>
                        <p className="homepage-second-main-title-desktop homepage-second-description small-text font-style-montserrat-family-regular color-white">Lynxai RCR focuses on helping asset managers report their investments and comply with regulations, effortlessly.</p>
                        <div className="homepage-second-icons-div-desktop">
                            <div className="homepage-second-points">
                                <img className="homepage-second-icon" src={automatic_reporting_icon} alt="img"/>
                                <div className="extra-small-text homepage-second-text-desktop font-style-montserrat-family-regular color-white">Automatic Reporting</div>
                            </div>
                            <div className="homepage-second-points">
                                <img className="homepage-second-icon" src={identify_liabilities_icon} alt="img"/>
                                <div className="extra-small-text homepage-second-text-desktop font-style-montserrat-family-regular color-white">Identification of regulatory liabilities in investment portfolios</div>
                            </div>
                            <div className="homepage-second-points">
                                <img className="homepage-second-icon" src={guarantee_compliance_icon} alt="img"/>
                                <div className="extra-small-text homepage-second-text-desktop font-style-montserrat-family-regular color-white">Guaranteed regulatory compliance</div>
                            </div>
                            <div className="homepage-second-points">
                                <img className="homepage-second-icon" src={real_time_icon_white} alt="img"/>
                                <div className="extra-small-text homepage-second-text-desktop font-style-montserrat-family-regular color-white">Modify portfolios in real-time to  meet regulatory goals</div>
                            </div>
                        </div>
                        <div className="homepage-second-icons-div-mobile">
                            <TransparentCarousel {...first_carousel_data} ></TransparentCarousel>
                        </div>
                        <div className="button-div">
                            <BlueButton link="/lynxai-rcr" text="Know More" fitContent="fixed-width" fontSize="small-text"></BlueButton>
                            <TransparentButton link="/contact-us" text="Try a Demo" fontSize="small-text" color="color-white"></TransparentButton>
                        </div>
                    </div>
                    <div className="homepage-second-image-div">
                        <img className="image-diagram" src={lynxai_rcr_diagram} alt="img"/>
                    </div>
                </div>
            </div>
            <div className="lynxai-vision-div">
                <div className="homepage-second-main-title-mobile extra-large-text homepage-second-title font-style-montserrat-family-regular color-white">Lynxai Vision</div>
                <p className="homepage-second-main-title-mobile homepage-second-description small-text font-style-montserrat-family-regular color-white">Lynxai Vision helps asset managers by collecting and providing analytics on billions of data points from multiple data sources.</p>
                <div className="lynxai-vision-inside-div">
                    <div className="homepage-second-image-div">
                        <div className="image-diagram-gradient"></div>
                        <img className="image-diagram" src={lynxai_vision_diagram} alt="img"/>
                    </div>
                    <div className="homepage-second-info-div">
                        <div className="homepage-second-main-title-desktop extra-large-text homepage-second-title font-style-montserrat-family-regular color-white">Lynxai Vision</div>
                        <p className="homepage-second-main-title-desktop homepage-second-description small-text font-style-montserrat-family-regular color-white">Lynxai Vision helps asset managers by collecting and providing analytics on billions of data points from multiple data sources.</p>
                        <div className="homepage-second-icons-div-desktop">
                            <div className="homepage-second-points">
                                <img className="homepage-second-icon" src={data_collection_icon_white} alt="img"/>
                                <div className="extra-small-text homepage-second-text-desktop font-style-montserrat-family-regular color-white">Public data collection from social media, news, and reports</div>
                            </div>
                            <div className="homepage-second-points">
                                <img className="homepage-second-icon" src={objetive_data_icon} alt="img"/>
                                <div className="extra-small-text homepage-second-text-desktop font-style-montserrat-family-regular color-white">Objective data, no subjective and opaque ratings, and scores</div>
                            </div>
                            <div className="homepage-second-points">
                                <img className="homepage-second-icon" src={social_media_trends_icon} alt="img"/>
                                <div className="extra-small-text homepage-second-text-desktop font-style-montserrat-family-regular color-white">Social media trend and red flag monitoring</div>
                            </div>
                            <div className="homepage-second-points">
                                <img className="homepage-second-icon" src={data_ml_ai_icon_white} alt="img"/>
                                <div className="extra-small-text homepage-second-text-desktop font-style-montserrat-family-regular color-white">Data handled with ML and AI technologies to draw insights and statistics</div>
                            </div>
                        </div>
                        <div className="homepage-second-icons-div-mobile">
                            <TransparentCarousel {...second_carousel_data} ></TransparentCarousel>
                        </div>
                        <div className="button-div">
                        <BlueButton link="/lynxai-vision" text="Know More" fitContent="fixed-width" fontSize="small-text"></BlueButton>
                            <TransparentButton link="/contact-us" text="Try a Demo" fontSize="small-text" color="color-white"></TransparentButton>
                         </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default HomepageSecond;