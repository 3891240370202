import React from "react";

import "./LynxaiRCRFirst.scss"

import RoundCornersMainImage from "../RoundCornersMainImage/RoundCornersMainImage";

const lynxai_rcr_background = require('../../assets/images/lynxai_rcr_background.png');

const round_image_data = {
    background_image: lynxai_rcr_background,
    text: "We help you with regulatory compliance and reporting"
}

const LynxaiRCRFirst = () => {
    return (
        <RoundCornersMainImage {...round_image_data}></RoundCornersMainImage>
    );
}

export default LynxaiRCRFirst;