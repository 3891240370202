import React from 'react';
import { MainDiv } from "../../assets/styles/global";

import ArticlesFirst from '../../components/ArticlesFirst/ArticlesFirst';
import ArticlesSecond from '../../components/ArticlesSecond/ArticlesSecond';

const Articles = () =>{
  return (
    <MainDiv>
      <ArticlesFirst></ArticlesFirst>
      <ArticlesSecond></ArticlesSecond>
    </MainDiv >
  );
}
export default Articles;
