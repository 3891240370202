import React from 'react';

import ThreeReasons from '../ThreeReasons/ThreeReasons';

import "./AboutUsThird.scss"


const target_icon = require('../../assets/images/target_icon.png');
const hands_icon = require('../../assets/images/hands_icon.png');
const leaf_icon_black = require('../../assets/images/leaf_icon_black.png');

const points = {
  title: "Our Objective",
  moreHeight: "normal-height",
  first_icon: target_icon,
    first_title: "Data Aggregation",
    first_text: "Aggregate all relevant ESG data under one platform only.",
    second_icon: hands_icon,
    second_title: "Data Filtering",
    second_text: "Filter all the data according to the client’s own standards and demands.",
    third_icon: leaf_icon_black,
    third_title: "Simplify Regulations",
    third_text: "Make the process of complying with complex regulations and reporting be only a few clicks away."
}

const AboutUsThird = () =>{
  return (
    <ThreeReasons {...points}></ThreeReasons>
  );
}
export default AboutUsThird;
