import React from 'react';

import RoundCornersMainImage from "../RoundCornersMainImage/RoundCornersMainImage";

import "./PAIFirst.scss"

const pai_background_image = require('../../assets/images/pai_background_image.png');

const round_image_data = {
    background_image: pai_background_image,
    text: "We help you comply with SFDR PAI"
}

const PAIFirst = () =>{
  return (
      <RoundCornersMainImage {...round_image_data}></RoundCornersMainImage>
  );
}
export default PAIFirst;
