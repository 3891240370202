import React from "react";

import GrayCarousel from "../../components/GrayCarousel/GrayCarousel";

import "./WhyChooseLynxai.scss"

const WhyChooseLynxai = () => {
    return (
        <div className="homepage-third-second-row">
            <div className="why-choose-lynxai extra-large-text font-style-montserrat-family-regular color-black">Why Choose Lynxai</div>
            <GrayCarousel></GrayCarousel>
        </div>
    );
}

export default WhyChooseLynxai;