

const putData = async (email) => {

    var params = {
        "email": email,
    }

    fetch('https://euok9cajgi.execute-api.eu-west-2.amazonaws.com/prod/write_email', {
        method: 'put',
        headers: {
            "Content-Type": "application/json",
            "X-Api-Key": "i3pQeQTFhI7IAm0LNeAyHaoZ1ZTXuijWaprahK0C",
        },
        body: JSON.stringify(params)
    }).then(response => response.json())

}
export default putData;
