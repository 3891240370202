import React from 'react';
import { MainDiv } from "../../assets/styles/global";

import ContactUsFirst from '../../components/ContactUsFirst/ContactUsFirst';
import ContactUsSecond from "../../components/ContactUsSecond/ContactUsSecond"
import ContactUsMap from "../../components/ContactUsMap/ContactUsMap"

const ContactUs = () =>{
  return (
    <MainDiv>
      <ContactUsFirst></ContactUsFirst>
      <ContactUsSecond></ContactUsSecond>
      <ContactUsMap></ContactUsMap>
    </MainDiv>
  );
}
export default ContactUs;
