import React from "react";
import { Pagination, Autoplay } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

import "./GrayCarousel.scss"

import 'swiper/scss';
import 'swiper/scss/pagination';

const hands_icon = require('../../assets/images/hands_icon.png');
const target_icon = require('../../assets/images/target_icon.png');
const share_icon = require('../../assets/images/share_icon.png');

const GrayCarousel = () => {
    return (
        <div>
            <div className="desktop-why-choose-lynxai">
                <div className="homepage-third-reason-div reason-1">
                    <img className="homepage-third-reason-icon" src={hands_icon} alt="img"/>
                    <div className="homepage-third-reason-title medium-text font-style-montserrat-family-regular color-black">Consistency on ESG Data</div>
                    <p className="homepage-third-reason-text extra-small-text font-style-montserrat-family-regular color-black">
                        We cross validate the data between all platforms that we go to, to ensure that there isn’t greenwashing or false data.
                    </p>
                </div>
                <div className="homepage-third-reason-div reason-2">
                    <img className="homepage-third-reason-icon" src={target_icon} alt="img"/>
                    <div className="homepage-third-reason-title medium-text font-style-montserrat-family-regular color-black">Control ESG Standards</div>
                    <p className="homepage-third-reason-text extra-small-text font-style-montserrat-family-regular color-black">
                        All the data on our platform is filtered according to our client’s standards to meet the objectives of multiple investment funds with different criteria.
                    </p>
                </div>
                <div className="homepage-third-reason-div reason-3">
                    <img className="homepage-third-reason-icon" src={share_icon} alt="img"/>
                    <div className="homepage-third-reason-title medium-text font-style-montserrat-family-regular color-black">Trend monitoring</div>
                    <p className="homepage-third-reason-text extra-small-text font-style-montserrat-family-regular color-black">
                        We are constantly monitoring social media to alert our clients about relevant trends that can present possible investment opportunities or red flags.
                    </p>
                </div>
            </div>
            <div className="tablet-mobile-why-choose-lynxai">
                <Swiper modules={[Autoplay, Pagination]}
                    pagination={{
                        el: '.gray-carousel-pagination-div',
                        clickable: true,
                        renderBullet: (index, className) => {
                            return '<span class="' + className + '"></span>';
                        },
                    }} loop={true} autoplay={{ delay: 4000, disableOnInteraction: false}} speed={300} centeredSlides={true} slidesPerView="1.5" spaceBetween={10}>
                    <SwiperSlide>
                        <div className="homepage-third-reason-div reason-1">
                        <img className="homepage-third-reason-icon" src={hands_icon} alt="img"/>
                        <div className="homepage-third-reason-title medium-text font-style-montserrat-family-regular color-black">Consistency on ESG Data</div>
                        <p className="homepage-third-reason-text small-text font-style-montserrat-family-regular color-black">
                            We cross validate the data between all platforms that we go to, to ensure that there isn’t greenwashing or false data.
                        </p>
                        </div>
                    </SwiperSlide>
                    <SwiperSlide>
                        <div className="homepage-third-reason-div reason-2">
                        <img className="homepage-third-reason-icon" src={target_icon} alt="img"/>
                        <div className="homepage-third-reason-title medium-text font-style-montserrat-family-regular color-black">Control ESG Standards</div>
                        <p className="homepage-third-reason-text small-text font-style-montserrat-family-regular color-black">
                            All the data on our platform is filtered according to our client’s standards to meet the objectives of multiple investment funds with different criteria.
                        </p>
                        </div>
                    </SwiperSlide>
                    <SwiperSlide>
                        <div className="homepage-third-reason-div reason-3">
                        <img className="homepage-third-reason-icon" src={share_icon} alt="img"/>
                        <div className="homepage-third-reason-title medium-text font-style-montserrat-family-regular color-black">Trend monitoring</div>
                        <p className="homepage-third-reason-text small-text font-style-montserrat-family-regular color-black">
                            We are constantly monitoring social media to alert our clients about relevant trends that can present possible investment opportunities or red flags.
                        </p>
                        </div>
                    </SwiperSlide>
                </Swiper>
                <div className="gray-carousel-pagination-div" />
            </div>
        </div>
    );
}

export default GrayCarousel;