

import React, { useState, useRef } from 'react';
import emailjs from '@emailjs/browser';

import "./FormMessage.scss"

const FormMessage = (props) => {
    const { messagePlaceholder, mailTemplate } = props;
    const form = useRef();

    const [values, setValues] = useState({
        firstName: '', lastName: '', email: '', subject: '', message: ''
    });
    const set = name => {
        return ({ target: { value } }) => {
            setValues(oldValues => ({ ...oldValues, [name]: value }));
        }
    };

    const onSubmit = async (event) => {
        event.preventDefault(); // Prevent default submission
        try {
            sendEmail()
        } catch (e) {
            alert('Submission Failed.');
        }
    }

    const sendEmail = (data) => {
        emailjs.sendForm('contact_form_website', mailTemplate, form.current, 'JEJuGBtp056M6p2Xu')
            .then((result) => {
                setValues({
                    firstName: '', lastName: '', email: '', subject: '', message: ''
                });
                window.location.href = '/submitted-form';
            }, (error) => {
                console.log(error.text);
            });
    };

    return (
        <form ref={form} onSubmit={onSubmit}>
            <div className="contact-us-first-form-div">
                <div className="half-input-div">
                    <input className="half-input extra-small-text font-style-montserrat-family-regular" name="first_name" type="text" placeholder="First Name*" value={values.firstName} onChange={set('firstName')} required />
                    <input className="half-input extra-small-text font-style-montserrat-family-regular" name="last_name" type="text" placeholder="Last Name*" value={values.lastName} onChange={set('lastName')} required />
                </div>
                <div className="full-input-div">
                    <input id="email-input-field" className="extra-small-text full-input font-style-montserrat-family-regular" name="email" type="email" placeholder="Email*" value={values.email} onChange={set('email')} required />
                    <input className="input-field extra-small-text full-input font-style-montserrat-family-regular" name="subject" type="text" placeholder="Subject*" value={values.subject} onChange={set('subject')} required />
                    <textarea id="message-input" className="extra-small-text full-input font-style-montserrat-family-regular" name="message" type="text" placeholder={messagePlaceholder} value={values.message} onChange={set('message')} required></textarea>
                </div>
                <p className="required-field-label extra-extra-small-text font-style-montserrat-family-regular color-lightgray">*Required fields</p>
                <input className="submit-button-form font-style-montserrat-family-regular color-white" type="submit" value="Submit" />
            </div>
        </form>
    );
}

export default FormMessage;