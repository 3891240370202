import React from "react";

import TransparentThreeRowsCarousel from "../TransparentThreeRowsCarousel/TransparentThreeRowsCarousel"
import BlueButton from "../BlueButton/BlueButton"

import "./LynxaiRCRSecond.scss"

const lynxai_rcr_image = require('../../assets/images/lynxai_rcr_image.png');
const homepage_2 = require('../../assets/images/homepage_2.png');
const automatic_reporting_icon = require('../../assets/images/automatic_reporting_icon_black.png');
const server_icon_black = require('../../assets/images/server_icon_black.png');
const pin_icon_black = require('../../assets/images/pin_icon_black.png');
const four_squares_icon_black = require('../../assets/images/four_squares_icon_black.png');

const first_carousel_data = {
    carousel_class: "first-carousel",
    colors: {
        font_color: "black",
        border_color: "darkgray"
    },
    carousel_data: {
        1: {
            icon: automatic_reporting_icon,
            title: "Automatic Reporting",
            text: "Generate automatic reports from the data on our dashboards in a few clicks."
        },
        2: {
            icon: server_icon_black,
            title: "Identification of regulatory liabilities in investment portfolios",
            text: "Identify liabilities easily with our data visualizations and drill down options on each company."
        },
        3: {
            icon: pin_icon_black,
            title: "Guaranteed regulatory compliance",
            text: "We guarantee regulatory compliance through a quick and simple process."
        },
        4: {
            icon: four_squares_icon_black,
            title: "Modify portfolios in real-time to  meet regulatory goals",
            text: "Modify your portfolios, and watch changes take effect on real-time to meet your objectives."
        }
    }
}

const LynxaiRCRSecond = () => {
    return (
        <div className="regulatory-compliance-div">
            <div className="regulatory-compliance-inside-div">
                <div className="regulatory-compliance-image-div">
                    <img className="lynxai-rcr-second-image-1" src={lynxai_rcr_image} alt="img"/>
                    <img className="lynxai-rcr-second-image-2" src={homepage_2} alt="img"/>
                </div>
                <div className="regulatory-compliance-info-div">
                    <div className="lynxai-rcr-second-title-desktop lynxai-rcr-second-title extra-large-text font-style-montserrat-family-regular color-black">Lynxai RCR: <br />Regulatory Compliance & Reporting</div>
                    <div className="lynxai-rcr-second-icons-div-desktop">
                        <div className="lynxai-rcr-second-points">
                            <img className="lynxai-rcr-second-icon" src={automatic_reporting_icon} alt="img"/>
                            <div className="small-text lynxai-rcr-second-text font-style-montserrat-family-regular">Automatic Reporting</div>
                            <div className="extra-small-text lynxai-rcr-second-text font-style-montserrat-family-regular color-lightgray">Generate automatic reports from the data on our dashboards in a few clicks.</div>
                        </div>
                        <div className="lynxai-rcr-second-points">
                            <img className="lynxai-rcr-second-icon" src={server_icon_black} alt="img"/>
                            <div className="small-text lynxai-rcr-second-text font-style-montserrat-family-regular">Guaranteed regulatory compliance</div>
                            <div className="extra-small-text lynxai-rcr-second-text font-style-montserrat-family-regular color-lightgray">We guarantee regulatory compliance through a quick and simple process.</div>
                        </div>
                        <div className="lynxai-rcr-second-points">
                            <img className="lynxai-rcr-second-icon" src={pin_icon_black} alt="img"/>
                            <div className="small-text lynxai-rcr-second-text font-style-montserrat-family-regular">Identification of regulatory liabilities in investment portfolios</div>
                            <div className="extra-small-text lynxai-rcr-second-text font-style-montserrat-family-regular color-lightgray">Identify liabilities easily with our data visualizations and drill down options on each company.</div>
                        </div>
                        <div className="lynxai-rcr-second-points">
                            <img className="lynxai-rcr-second-icon" src={four_squares_icon_black} alt="img"/>
                            <div className="small-text lynxai-rcr-second-text font-style-montserrat-family-regular">Modify portfolios in real-time to meet regulatory goals</div>
                            <div className="extra-small-text lynxai-rcr-second-text font-style-montserrat-family-regular color-lightgray">Modify your portfolios, and watch changes take effect on real-time to meet your objectives.</div>
                        </div>
                    </div>
                    <div className="lynxai-rcr-second-title-mobile lynxai-rcr-second-title extra-large-text font-style-montserrat-family-regular color-black">Lynxai RCR: <br />Regulatory Compliance & Reporting</div>
                    <div className="lynxai-rcr-second-icons-div-mobile">
                        <TransparentThreeRowsCarousel {...first_carousel_data} ></TransparentThreeRowsCarousel>
                    </div>
                    <div className="lynxai-rcr-try-demo-div">
                        <BlueButton link="/request-demo" text="Try a Demo" fitContent="fixed-width" fontSize="small-text"></BlueButton>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default LynxaiRCRSecond;