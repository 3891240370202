import React from 'react';

import "./MentorDetailsDesktop.scss";

const linkedin_icon = require('../../assets/images/linkedin-logo-white.png');
const bullet_point = require('../../assets/images/bullet_point_gray.png');

const MentorDetailsDesktop = (props) => {
    const { photo, name, linkedinLink, position, location, experience } = props;

    return (
        <div className="mentor-details">
            <img className="mentor-img" src={photo} alt="img"></img>
            <div className="mentor-text-div">
                <div className="mentor-name-linkedin">
                    <p className="small-text font-style-montserrat-family-regular color-black">{name}</p>
                    <a href={linkedinLink} target="_blank"><div className="about-us-fifth-linkedin-button"><img className="about-us-fifth-linkedin-image" src={linkedin_icon} alt="img"></img></div></a>
                </div>
                <p className="about-us-fifth-mentor-experience extra-small-text font-style-montserrat-family-regular color-blue">{position}</p>
                <p className="about-us-fifth-mentor-location extra-small-text font-style-montserrat-family-regular color-lightgray">{location}</p>
                <div className="about-us-fifth-experience-main-div">
                    {Object.keys(experience).map((key, index) => (
                    <div key={index} className="about-us-fifth-experience-div">
                        <img className="about-us-fifth-experience-bullet" src={bullet_point} alt="img"></img>
                        <p className="about-us-fifth-experience-text extra-small-text font-style-montserrat-family-regular color-lightgray">{experience[key]}</p>
                    </div>
                    ))}
                </div>
            </div>
        </div>
    );
}
export default MentorDetailsDesktop;
