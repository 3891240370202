import React from "react";
import "./MainLoader.scss";

const main_loader = require('../../assets/images/main_loader.gif')

function MainLoader() {
  return (
   <div className="loader-container">
    <img
        src={main_loader}
        style={{ width: '100px', margin: 'auto', display: 'block' }}
        alt="img"/>
   </div>
  );
}

export default MainLoader;