import React from 'react';

import "./ThreeReasons.scss"

const linkedin_icon = require('../../assets/images/linkedin-logo-white.png');

const ThreeReasons = (props) => {
    const { title, moreHeight, first_icon, first_title, first_text, first_anchor, second_icon, second_title, second_text, second_anchor, third_icon, third_title, third_text, third_anchor } = props;

    let first_element;
    let second_element;
    let third_element;
    if (first_anchor) {
        first_element = <a href={first_anchor} target="_blank"><p className="email-us-lynxai-mail three-reasons-second-reason-text small-text font-style-montserrat-family-regular color-blue">{first_text}</p></a>
    } else {
        first_element = <p className="three-reasons-second-reason-text small-text font-style-montserrat-family-regular color-black">{first_text}</p>
    }
    if (second_anchor) {
        second_element = <a href={second_anchor}><p className="email-us-lynxai-mail three-reasons-second-reason-text small-text font-style-montserrat-family-regular color-blue">{second_text}</p></a>
    } else {
        second_element = <p className="three-reasons-second-reason-text small-text font-style-montserrat-family-regular color-black">{second_text}</p>
    }
    if (third_anchor) {
        third_element = <a href={third_anchor} target="_blank"><div id="message-us-linked-in-button" ><p className="small-text font-style-montserrat-family-regular">{third_text}</p><div className="three-reasons-linkedin-button"><img className="three-reasons-linkedin-image" src={linkedin_icon} alt="img"></img></div></div></a>
    } else {
        third_element = <p className="three-reasons-second-reason-text small-text font-style-montserrat-family-regular color-black">{third_text}</p>
    }

    return (
        <div className="three-reasons-main-div">
            <p className="three-reasons-title extra-large-text font-style-montserrat-family-regular color-black">{title}</p>
            <div className="desktop-three-reasons-second">
                <div className={`${moreHeight} three-reasons-second-reason-div reason-1`}>
                    <img className="three-reasons-second-reason-icon" src={first_icon} alt="img"/>
                    <div className="three-reasons-second-reason-title medium-text font-style-montserrat-family-regular color-black">{first_title}</div>
                        {first_element}
                </div>
                <div className={`${moreHeight} three-reasons-second-reason-div reason-2`}>
                    <img className="three-reasons-second-reason-icon" src={second_icon} alt="img"/>
                    <div className="three-reasons-second-reason-title medium-text font-style-montserrat-family-regular color-black">{second_title}</div>
                    {second_element}
                </div>
                <div className={`${moreHeight} three-reasons-second-reason-div reason-3`}>
                    <img className="three-reasons-second-reason-icon" src={third_icon} alt="img"/>
                    <div className="three-reasons-second-reason-title medium-text font-style-montserrat-family-regular color-black">{third_title}</div>
                    {third_element}
                </div>
            </div>
        </div>
    );
}
export default ThreeReasons;
