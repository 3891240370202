import React, { useState } from 'react';
import { NavLink } from "react-router-dom";
import { StyledBurger, Ul, hide_background, darkenBackground } from "./NavbarStyles"

import BlueButton from "../BlueButton/BlueButton"
import "./Navbar.scss"

const lynxai_line_logo = require('../../assets/images/lynxai_line_logo.png');

const Navbar = () => {
  const [rightMenuOpen, setrightMenuOpen] = useState(false)

  return (
    <div className="header-div">
      <div id="overlay" onClick={function () { hide_background(rightMenuOpen); setrightMenuOpen(!rightMenuOpen) }}></div>
      <NavLink to="/">
        <div className="icon-div">
          <img className="name-img" src={lynxai_line_logo} alt="img" />
        </div>
      </NavLink>
      <div className="navbar">
        <div className="menu-desktop">
          <div className="div-lynxai-rcr">
            <NavLink to="/lynxai-rcr" className="color-black" style={({ isActive }) => ({ color: isActive ? 'var(--live-blue)' : 'var(--eerie-black)' })}>
              <div className="navbar-link small-text font-style-montserrat-family-semibold">Lynxai RCR</div>
            </NavLink>
            <div className="dropdown-div">
              <NavLink to="/eu-taxonomy" className="color-white" style={({ isActive }) => ({ color: isActive ? 'lightgray' : 'white' })}>
                <div className="dropdown-button extra-small-text font-style-montserrat-family-semibold">EU Taxonomy</div>
              </NavLink>
              <hr></hr>
              <NavLink to="/pai" className="color-white" style={({ isActive }) => ({ color: isActive ? 'lightgray' : 'white' })}>
                <div className="dropdown-button extra-small-text font-style-montserrat-family-semibold">PAI</div>
              </NavLink>
            </div>
          </div>
          <NavLink to="/lynxai-vision" className="color-black" style={({ isActive }) => ({ color: isActive ? 'var(--live-blue)' : 'var(--eerie-black)' })}>
            <div className="navbar-link small-text font-style-montserrat-family-semibold">Lynxai Vision</div>
          </NavLink>
          <NavLink to="/about-us" className="color-black" style={({ isActive }) => ({ color: isActive ? 'var(--live-blue)' : 'var(--eerie-black)' })}>
            <div className="navbar-link small-text font-style-montserrat-family-semibold">About Us</div>
          </NavLink>
          <NavLink to="/articles" className="color-black" style={({ isActive }) => ({ color: isActive ? 'var(--live-blue)' : 'var(--eerie-black)' })}>
            <div className="navbar-link small-text font-style-montserrat-family-semibold">Articles</div>
          </NavLink>
          <NavLink to="/contact-us" className="color-black" style={({ isActive }) => ({ color: isActive ? 'var(--live-blue)' : 'var(--eerie-black)' })}>
            <div className="navbar-link small-text font-style-montserrat-family-semibold">Contact Us</div>
          </NavLink>
        </div>
        <div className="right-button">
          <BlueButton link="/request-demo" text="Request a Demo" fitContent="fit-content" fontSize="small-text"></BlueButton>
        </div>
        <StyledBurger open={rightMenuOpen} onClick={function () { darkenBackground(rightMenuOpen); setrightMenuOpen(!rightMenuOpen) }}>
          <div />
          <div />
          <div />
        </StyledBurger>
        <Ul open={rightMenuOpen}>
          <NavLink onClick={function () { hide_background(rightMenuOpen); setrightMenuOpen(!rightMenuOpen) }} style={({ isActive }) => ({ color: isActive ? 'var(--live-blue)' : 'var(--eerie-black)' })} className="font-style-montserrat-family-regular medium-text" to="/lynxai-rcr">
            <li>Lynxai RCR </li>
          </NavLink>
          <NavLink onClick={function () { hide_background(rightMenuOpen); setrightMenuOpen(!rightMenuOpen) }} id="eu-taxonomy" style={({ isActive }) => ({ color: isActive ? 'var(--live-blue)' : 'var(--eerie-black)' })} className="font-style-montserrat-family-regular medium-text" to="/eu-taxonomy">
            <li><span className="sub-item-text">EU Taxonomy</span></li>
          </NavLink>
          <NavLink onClick={function () { hide_background(rightMenuOpen); setrightMenuOpen(!rightMenuOpen) }} style={({ isActive }) => ({ color: isActive ? 'var(--live-blue)' : 'var(--eerie-black)' })} className="font-style-montserrat-family-regular medium-text" to="/lynxai-vision">
            <li>Lynxai Vision</li>
          </NavLink>
          <NavLink onClick={function () { hide_background(rightMenuOpen); setrightMenuOpen(!rightMenuOpen) }} style={({ isActive }) => ({ color: isActive ? 'var(--live-blue)' : 'var(--eerie-black)' })} className="font-style-montserrat-family-regular medium-text" to="/about-us">
            <li>About Us</li>
          </NavLink>
          <NavLink onClick={function () { hide_background(rightMenuOpen); setrightMenuOpen(!rightMenuOpen) }} style={({ isActive }) => ({ color: isActive ? 'var(--live-blue)' : 'var(--eerie-black)' })} className="font-style-montserrat-family-regular medium-text" to="/articles">
            <li>Articles</li>
          </NavLink>
          <NavLink onClick={function () { hide_background(rightMenuOpen); setrightMenuOpen(!rightMenuOpen) }} style={({ isActive }) => ({ color: isActive ? 'var(--live-blue)' : 'var(--eerie-black)' })} className="font-style-montserrat-family-regular medium-text" to="/contact-us">
            <li style={{ border: 'none' }}>Contact Us</li>
          </NavLink>
        </Ul>
      </div>
    </div>
  );
}

export default Navbar;