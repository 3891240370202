import React from "react";
import { Link } from "react-router-dom";

import "./TransparentButton.scss"

const TransparentButton = (props) => {
  const { text, color, border, link, component, fontSize } = props;

  return (
    <Link to={link}>
      <div className={`transparent-button ${fontSize} ${component} font-style-montserrat-family-regular ${color} ${border}`}>{text}</div>
    </Link>
  );
}

export default TransparentButton;