import React from 'react';

import "./AboutUsFirst.scss";

import RoundCornersMainImage from "../RoundCornersMainImage/RoundCornersMainImage";

const about_us_background_image = require('../../assets/images/about_us_background_image.png');

const round_image_data = {
    background_image: about_us_background_image,
    text: "Lynxai was created by people that believe in the power that finance can have in terms of environmental & social impact.",
    showNoShow: "hide-buttons-div"
}

const AboutUsFirst = () =>{
  return (
    <RoundCornersMainImage {...round_image_data}></RoundCornersMainImage>
  );
}
export default AboutUsFirst;
