import React from "react";

import "./ArticlesFirst.scss"

const main_article_img = require('../../assets/images/main_article_img.png');
const clock_icon_white = require('../../assets/images/clock_icon_white.png');
const clock_icon_black = require('../../assets/images/clock_icon_black.png');
const small_article_1_img = require('../../assets/images/small_article_1.png');
const small_article_2_img = require('../../assets/images/small_article_2.png');
const small_article_3_img = require('../../assets/images/small_article_3.png');
const small_article_4_img = require('../../assets/images/small_article_4.png');
const small_article_5_img = require('../../assets/images/small_article_5.png');
const small_article_6_img = require('../../assets/images/small_article_6.png');

const ArticlesFirst = (props) => {

    return (
        <div className="articles-first-main-div">
            <p className="three-reasons-title extra-large-text font-style-montserrat-family-regular color-black">Articles & Insights</p>
            <div className="articles-first-most-read-articles-title-div">
                <p className="articles-first-most-read-articles-title-text medium-text font-style-montserrat-family-regular color-black">Most read articles</p>
                <div className="articles-first-line-divider"></div>
            </div>
            <div className="desktop articles-first-block-div">
                <div className="articles-first-main-article">
                    <a target="_blank" href="https://www.startuplisboa.com/blog/thinkgreenlynxai">
                        <div className="articles-first-main-article-div" style={{ backgroundImage: `url(${main_article_img})` }}>
                            <div className="homepage-fourth-darker-main-article"></div>
                            <div className="main-article-info-div">
                                <p className="homepage-fourth-main-article-text large-text font-style-montserrat-family-regular color-white">THINK GREEN WITH LYNXAI</p>
                                <div className="homepage-fourth-time-read-div">
                                    <img className="homepage-fourth-clock-icon " src={clock_icon_white} alt="img"/>
                                    <div className="homepage-fourth-time medium-text font-style-montserrat-family-regular color-white">5 min read</div>
                                </div>
                            </div>
                        </div>
                    </a>
                </div>
                <div className="articles-first-small-articles">
                    <div className="homepage-fourth-small-articles-col">
                        <a target="_blank" href="https://www.linkedin.com/pulse/aggregate-confusion-divergence-esg-ratings-mit-paper-lynxai/">
                            <div className="homepage-fourth-small-article-div">
                                <img className="homepage-fourth-small-article-background" src={small_article_1_img} alt="img"/>
                                <div className="homepage-fourth-small-article-info-div">
                                    <p className="homepage-fourth-small-article-title small-text font-style-montserrat-family-regular">The Divergence of ESG Ratings</p>
                                    <div className="homepage-fourth-time-small-read-div">
                                        <img className="homepage-fourth-clock-icon color-black" src={clock_icon_black} alt="img"/>
                                        <div className="homepage-fourth-time extra-small-text font-style-montserrat-family-regular color-black">2 min read</div>
                                    </div>
                                </div>
                            </div>
                        </a>
                        <a target="_blank" href="https://www.linkedin.com/pulse/1-esg-challenge-organizations-face-data-world-economic-forum-/">
                            <div className="homepage-fourth-small-article-div ">
                                <img className="homepage-fourth-small-article-background" src={small_article_2_img} alt="img"/>
                                <div className="homepage-fourth-small-article-info-div">
                                    <p className="homepage-fourth-small-article-title small-text font-style-montserrat-family-regular">ESG challenge organizations face</p>
                                    <div className="homepage-fourth-time-small-read-div">
                                        <img className="homepage-fourth-clock-icon" src={clock_icon_black} alt="img"/>
                                        <div className="homepage-fourth-time extra-small-text font-style-montserrat-family-regular color-black">2 min read</div>
                                    </div>
                                </div>
                            </div>
                        </a>
                        <a target="_blank" href="https://www.linkedin.com/pulse/robert-kiyosaki-writer-bestselling-novel-rich-dad-poor-took-twitter-/">
                            <div className="homepage-fourth-small-article-div">
                                <img className="homepage-fourth-small-article-background" src={small_article_3_img} alt="img"/>
                                <div className="homepage-fourth-small-article-info-div">
                                    <p className="homepage-fourth-small-article-title small-text font-style-montserrat-family-regular">Robert Kiyosaki Article</p>
                                    <div className="homepage-fourth-time-small-read-div">
                                        <img className="homepage-fourth-clock-icon" src={clock_icon_black} alt="img"/>
                                        <div className="homepage-fourth-time extra-small-text font-style-montserrat-family-regular color-black">4 min read</div>
                                    </div>
                                </div>
                            </div>
                        </a>
                    </div>
                    <div className="homepage-fourth-small-articles-col homepage-fourth-second-col">
                            <a target="_blank" href="https://www.linkedin.com/posts/luxembourg-trade-and-invest_esg-luxembourg-us-activity-6991385948357861376-tlEb?utm_source=share&utm_medium=member_desktop">
                                <div className="homepage-fourth-small-article-div">
                                    <img className="homepage-fourth-small-article-background" src={small_article_4_img} alt="img"/>
                                    <div className="homepage-fourth-small-article-info-div">
                                        <p className="homepage-fourth-small-article-title small-text font-style-montserrat-family-regular">Lynxai #Fit4Start Article</p>
                                        <div className="homepage-fourth-time-small-read-div">
                                            <img className="homepage-fourth-clock-icon" src={clock_icon_black} alt="img"/>
                                            <div className="homepage-fourth-time extra-small-text font-style-montserrat-family-regular color-black">2 min read</div>
                                        </div>
                                    </div>
                                </div>
                            </a>
                            <a target="_blank" href="https://techsense.lu/news/catapult-kickstarter-2022-summer-edition">
                                <div className="homepage-fourth-small-article-div ">
                                    <img className="homepage-fourth-small-article-background" src={small_article_5_img} alt="img"/>
                                    <div className="homepage-fourth-small-article-info-div">
                                        <p className="homepage-fourth-small-article-title small-text font-style-montserrat-family-regular">Catapult: Kickstarted 2022 Summer Edition</p>
                                        <div className="homepage-fourth-time-small-read-div">
                                            <img className="homepage-fourth-clock-icon" src={clock_icon_black} alt="img"/>
                                            <div className="homepage-fourth-time extra-small-text font-style-montserrat-family-regular color-black">10 min read</div>
                                        </div>
                                    </div>
                                </div>
                            </a>
                            <a target="_blank" href="https://fundspeople.com/pt/a-plataforma-portuguesa-que-promete-simplificar-os-dados-esg-para-as-gestoras-de-ativos/">
                                <div className="homepage-fourth-small-article-div">
                                    <img className="homepage-fourth-small-article-background" src={small_article_6_img} alt="img"/>
                                    <div className="homepage-fourth-small-article-info-div">
                                        <p className="homepage-fourth-small-article-title small-text font-style-montserrat-family-regular">FundsPeople Article</p>
                                        <div className="homepage-fourth-time-small-read-div">
                                            <img className="homepage-fourth-clock-icon" src={clock_icon_black} alt="img"/>
                                            <div className="homepage-fourth-time extra-small-text font-style-montserrat-family-regular color-black">7 min read</div>
                                        </div>
                                    </div>
                                </div>
                            </a>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ArticlesFirst;