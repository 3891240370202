import React from "react";
import { Link } from "react-router-dom";
import putData from "../../utils/AwsFunctions/put"

import BlueTransparentButtonsDiv from "../BlueTransparentButtonsDiv/BlueTransparentButtonsDiv"

import "./Footer.scss"

const lynxai_logo_white = require('../../assets/images/lynxai_logo_white.png');
const small_loader = require('../../assets/images/small_loader.gif')

const saveEmailNewsletter = async (event) => {
    event.preventDefault()
    await putData(event.target.email.value)

    document.getElementById("send-icon-div").classList.toggle('fade-out');

    setTimeout(() => {
        document.getElementById("send-icon-div").style.display = "none";
        document.getElementById("small-loader-gif").style.display = "block";
    }, 500);
    setTimeout(() => {
        document.getElementById("small-loader-gif").classList.toggle('fade-in');
    }, 500);
    setTimeout(() => {
        document.getElementById("join-newsletter-form").classList.toggle('fade-out');
        setTimeout(() => {
            document.getElementById("join-newsletter-form").style.display = "none";
            document.getElementById("join-newsletter-done").classList.toggle('fade-in');
        }, 1000);
    }, 3000);
}

const Footer = () => {

    return (
        <div className="container-footer">
            <div className="main-content">
                <div className="main-content-col">
                    <div className="medium-text font-style-montserrat-family-regular color-white">Get Started</div>
                    <BlueTransparentButtonsDiv flow="flow-column" component="footer" colorTransparentButton="color-white" transparentButtonText="Talk with Us" fontSize="extra-small-text"></BlueTransparentButtonsDiv>
                </div>
                <div className="main-content-col">
                    <div className="medium-text font-style-montserrat-family-regular color-white">Contact Us</div>
                    <a href="mailto:tiagofa@lynxai.tech">
                        <div className="connection email-div">
                            <div className="connection-icon-div" ><img className="email-icon" alt="img"/></div>
                            <div className="connection-name extra-small-text font-style-montserrat-family-regular color-white">tiagofa@lynxai.tech</div>
                        </div>
                    </a>
                </div>
                <div className="main-content-col">
                    <div className="medium-text font-style-montserrat-family-regular color-white">Follow Us</div>
                    <a href="https://www.linkedin.com/company/lynxai/" target="_blank">
                        <div className="connection linkedin-div">
                            <div className="connection-icon-div" ><img className="linkedin-icon" alt="img"/></div>
                            <div className="connection-name extra-small-text font-style-montserrat-family-regular color-white">Linkedin</div>
                        </div>
                    </a>
                </div>
                <div className="main-content-col">
                    <div className="medium-text font-style-montserrat-family-regular color-white">Join Newsletter</div>
                    <div className="div-join-newsletter">
                        <form id="join-newsletter-form" onSubmit={saveEmailNewsletter}>
                            <input type="email" className="input-email extra-small-text font-style-montserrat-family-regular color-white" name="email" placeholder="your@email.com" required></input>
                            <button id="send-icon-div" type="submit"><img className="send-icon" alt="img"/></button>
                            <img id="small-loader-gif" src={small_loader} alt="img"></img>
                        </form>
                        <span id="join-newsletter-done" className="extra-small-text font-style-montserrat-family-regular color-white">Email registered!</span>
                    </div>
                </div>
            </div>

            <div className="privacy-policy-div">
                <div className="copyright extra-small-text font-style-montserrat-family-regular color-white">Lynxai © Copyright 2022</div>
                <div className="lynxai-logo-div">
                    <Link to="/">
                        <img className="lynxai-logo" src={lynxai_logo_white} alt="img"/>
                    </Link>
                </div>
                <div className="privacy-and-terms extra-small-text font-style-montserrat-family-regular color-white">
                    <span>Privacy Policy</span>
                    <span className="text-divider"> | </span>
                    <span>Terms of Service</span>
                </div>
            </div>
        </div>
    );
}


export default Footer;
