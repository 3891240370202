import React from 'react';
import BlueButton from "../../components/BlueButton/BlueButton"

import "./PageNotFound.scss"

const alert = require('../../assets/images/alert.png');
const page_not_found = require('../../assets/images/page_not_found.png');

const PageNotFound = () => {
  return (
    <div className="error-404-div">
      <img id="alert-icon" src={alert} alt="img"/>
      <img id="image-404" src={page_not_found} alt="img"/>
      <BlueButton link="/" text="Back to Homepage" fitContent="fit-content" fontSize="exztra-small-text"></BlueButton>
    </div>
  );
}
export default PageNotFound;
