import React from 'react';

import BlueButton from "../BlueButton/BlueButton"
import TeamMemberDetails from '../TeamMemberDetails/TeamMemberDetails';

import "./AboutUsFourth.scss";

const eduardo = require('../../assets/images/eduardo_rebelo.png');
const experience_eduardo = {
    1: "Tech Expert", 
    2: "Data Analyst @ Iron Hack", 
    3: "Eletric Engineering @ IST", 
    4: "Developer Fashion Impact Score"
}

const tiago = require('../../assets/images/tiagofa.png');
const experience_tiago = {
    1: "Founder @ 14ºC", 
    2: "Founder @ Startup Kit", 
    3: "Master in Strategic Business", 
    4: "Mentored +75 early-stage startups"
}

const tomas = require('../../assets/images/tomasfsa.png');
const experience_tomas = {
    1: "Data Scientist @ Bank of Portugal",
    2: "Data Analyst @ Aptoide", 
    3: "Front-End Developer @ QuickMatch.io" 
}

const joao = require('../../assets/images/joao-mercier.png');
const experience_joao = {
    1: "Software Developer @ InnoWave",
    2: "AWS Certified Developer",
    3: "Software Developer in Automation @ WOW! Internet, Cables & Phone"
}

const mariia = require('../../assets/images/mariia_popova.png');
const experience_mariia = {
    1: "CEO & Founder @ NeDigital",
    2: "Marketing Manager @ Dassault Systèmes",
    3: "Marketing Assistant @ Tommy Hilfiger",
}

const reyhan = require('../../assets/images/reyhan.png');
const experience_reyhan = {
    1: "Chief Strategy Officer @ Growth Division",
    2: "Business Development Manager @ Client Accelerators",
    3: "Costumer Success Associate @ S&P Global"
}

const AboutUsFourth = () => {
    return (
        <div className="about-us-fourth-main-div">
            <p className="three-reasons-title extra-large-text font-style-montserrat-family-regular color-white">Meet The Team</p>
            <div className="about-us-fourth-team-div">
               <TeamMemberDetails photo={tiago} name="Tiago Freire de Andrade" position="CEO & Co-Founder" linkedinLink="https://www.linkedin.com/in/tiagofa/" experience={experience_tiago}></TeamMemberDetails>
               <TeamMemberDetails photo={eduardo} name="Eduardo Amaro Rebelo" position="CTO & Co-Founder" linkedinLink="https://www.linkedin.com/in/eduardo-rebelo-20/" experience={experience_eduardo}></TeamMemberDetails>
               <TeamMemberDetails photo={reyhan} name="Reyhan Khan" position="Marketing Operations Manager" linkedinLink="https://www.linkedin.com/in/reyhankhangd/" experience={experience_reyhan}></TeamMemberDetails>
               <TeamMemberDetails photo={mariia} name="Mariia Popova" position="Product Manager" linkedinLink="https://www.linkedin.com/in/mariia-popova-b7b832175/" experience={experience_mariia}></TeamMemberDetails>
               <TeamMemberDetails photo={joao} name="João Pedro Mercier" position="Software Engineer" linkedinLink="https://www.linkedin.com/in/jo%C3%A3o-mercier-06a641195/" experience={experience_joao}></TeamMemberDetails>
               <TeamMemberDetails photo={tomas} name="Tomás Ferreira de Sá" position="Software Engineer" linkedinLink="https://www.linkedin.com/in/tomasferreiradesa/" experience={experience_tomas}></TeamMemberDetails>
            </div>
            <BlueButton link="/contact-us" text="Talk with Us" fitContent="fixed-width" fontSize="small-text"></BlueButton>
        </div>
    );
}
export default AboutUsFourth;
