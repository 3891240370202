import React from 'react';
import { Pagination, Autoplay } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

import 'swiper/scss';
import 'swiper/scss/pagination';

import "./MentorCarousel.scss";

const linkedin_icon = require('../../assets/images/linkedin-logo-white.png');
const bullet_point = require('../../assets/images/bullet_point_gray.png');

const breakpoint = {
    // when window width is >= 370px
    370: {
        slidesPerView: 1.3,
        spaceBetween: 15
    },
    // when window width is >= 400px
    400: {
        slidesPerView: 1.4,
        spaceBetween: 15
    },
    // when window width is >= 450px
    450: {
        slidesPerView: 1.5,
        spaceBetween: 15
    },
    // when window width is >= 500px
    500: {
        slidesPerView: 1.6,
        spaceBetween: 10
    },
    // when window width is >= 550px
    550: {
        slidesPerView: 1.8,
        spaceBetween: 10
    },
    // when window width is >= 600px
    600: {
        slidesPerView: 2,
        spaceBetween: 5
    },
    // when window width is >= 650px
    650: {
        slidesPerView: 2.2,
        spaceBetween: 5
    },
    // when window width is >= 730px
    730: {
        slidesPerView: 2.4,
        spaceBetween: 5
    },
    // when window width is >= 800px
    800: {
        slidesPerView: 2.6,
        spaceBetween: 5
    },
}

const MentorCarousel = (props) => {
    const { mentors_info } = props;

    return (
        <div className="mentors-carousel-main-div">
            <Swiper modules={[Autoplay, Pagination]}
                pagination={{
                    el: '.mentors-carousel-pagination-div',
                    clickable: true,
                    renderBullet: (index, className) => {
                        return '<span class="' + className + '"></span>';
                    },
                }} loop={true} autoplay={{ delay: 4000, disableOnInteraction: false }} speed={300} centeredSlides={true} breakpoints={breakpoint} slidesPerView="1.2" spaceBetween={10}>
                {Object.keys(mentors_info).map((key, index) => (
                    <SwiperSlide key={index}>
                        <div className="mentor-details-carousel">
                            <img className="mentor-img-carousel" src={mentors_info[key].photo} alt="img"></img>
                            <div className="mentor-text-div-carousel">
                                <div className="mentor-name-linkedin">
                                    <p className="small-text font-style-montserrat-family-regular color-black">{mentors_info[key].name}</p>
                                    <a href={mentors_info[key].linkedinLink} target="_blank"><div className="about-us-fifth-linkedin-button"><img className="about-us-fifth-linkedin-image" src={linkedin_icon} alt="img"></img></div></a>
                                </div>
                                <p className="about-us-fifth-mentor-experience extra-small-text font-style-montserrat-family-regular color-blue">{mentors_info[key].position}</p>
                                <p className="about-us-fifth-mentor-location extra-small-text font-style-montserrat-family-regular color-lightgray">{mentors_info[key].location}</p>
                                <div className="about-us-fifth-experience-main-div-carousel">
                                    {Object.keys(mentors_info[key].experience).map((key_one, index_one) => (
                                        <div key={index_one} className="about-us-fifth-experience-div-carousel">
                                            <img className="about-us-fifth-experience-bullet-carousel" src={bullet_point} alt="img"></img>
                                            <p className="about-us-fifth-experience-text-carousel extra-small-text font-style-montserrat-family-regular color-black">{mentors_info[key].experience[key_one]}</p>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                    </SwiperSlide>
                ))}
            </Swiper>
            <div className="mentors-carousel-pagination-div"></div>
        </div>
    );
}
export default MentorCarousel;
