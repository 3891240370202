import React from 'react';

import "./PAISecond.scss";

const PAISecond = () => {
  return (
    <div className="pai-second-div">
      <p className="three-reasons-title extra-large-text  font-style-montserrat-family-regular color-black">SFDR Solutions PAI</p>
      <p className="pai-second-description medium-text font-style-montserrat-family-regular color-black">Investment managers face requirements and data challenges under the Sustainable Finance Disclosure Regulation (SFDR). Stay ahead of the game with Lynxai's technology solutions and make sustainable investments.</p>
    </div>
  );
}
export default PAISecond;
