import React from "react";

import "./BlueTransparentButtonsDiv.scss"

import BlueButton from "../BlueButton/BlueButton"
import TransparentButton from "../TransparentButton/TransparentButton"

const BlueTransparentButtonsDiv = (props) => {
    const { transparentButtonText, flow, component, colorTransparentButton, fontSize } = props;

    return (
        <div className={`buttons-div ${flow}`}>
            <BlueButton link="/request-demo" text="Try a Demo" fitContent="fixed-width" fontSize={fontSize} component={component}></BlueButton>
            <TransparentButton link="/contact-us" text={transparentButtonText} fontSize={fontSize} color={colorTransparentButton} component={component}></TransparentButton>
        </div>
    );
}

export default BlueTransparentButtonsDiv;