import React from "react";

import "./PAIFourth.scss"

import BlueButton from "../BlueButton/BlueButton"
import TransparentThreeRowsCarousel from "../TransparentThreeRowsCarousel/TransparentThreeRowsCarousel"

const lynxai_rcr_diagram = require('../../assets/images/lynxai_rcr_diagram.png');
const automatic_reporting_icon = require('../../assets/images/automatic_reporting_icon.png');
const identify_liabilities_icon = require('../../assets/images/identify_liabilities_icon.png');
const guarantee_compliance_icon = require('../../assets/images/guarantee_compliance_icon.png');
const real_time_icon_white = require('../../assets/images/real_time_icon_white.png');
const cloud_icon_white = require('../../assets/images/cloud_icon_white.png');

const first_carousel_data = {
    carousel_class: "first-carousel",
    colors: {
        font_color: "white",
        border_color: "lightgray"
    },
    carousel_data: {
        1: {
            icon: automatic_reporting_icon,
            title: "Save time by automating the calculation of PAI indicators.",
            text: "Lynxai offers a comprehensive solution to address this issue with its automated reporting system. The system draws from a dependable data source on listed companies and features a user-friendly reporting framework."
        },
        2: {
            icon: identify_liabilities_icon,
            title: "Comply and optimize your workload when creating PAI statements",
            text: "Our research teams and regulation department monitor all up-to-date disclosures and regulations on the financial markets which gives our clients 100% guarantee in compliance with all the changes and updates in regulations."
        },
        3: {
            icon: guarantee_compliance_icon,
            title: "Data complexity",
            text: "Analyzing PAI data can be challenging for investors who may lack resources and legal expertise, leading to incomplete or inaccurate reporting. Our technological solution provides secure assurance and simplifies the analysis process for investors."
        },
        4: {
            icon: real_time_icon_white,
            title: "Automatically request data disclosures for companies in your portfolio where there is missing data",
            text: "Lynxai platform solves this problem and gives an opportunity to both investors and private companies to create productive dialogue via this solution. Lynxai helps both parties in building trust and sustainable business strategies."
        },
    }
}

const PAIFourth = () => {
    return (
        <div className="pai-fourth-block-div">
            <div className="eu-taxonomy-div">
                <div className="pai-fourth-main-title-mobile extra-large-text pai-fourth-title font-style-montserrat-family-regular color-white">We empower your team to incorporate sustainability into your investment strategy.</div>
                <div className="eu-taxonomy-inside-div">
                    <div className="pai-fourth-info-div">
                        <div className="pai-fourth-main-title-desktop extra-large-text pai-fourth-title font-style-montserrat-family-regular color-white">We empower your team to incorporate sustainability into your investment strategy.</div>
                        <div className="pai-fourth-icons-div-desktop">
                            <div className="pai-fourth-points">
                                <img className="pai-fourth-icon" src={automatic_reporting_icon} alt="img"/>
                                <div className="small-text pai-fourth-main-text-desktop font-style-montserrat-family-regular color-white">Save time by automating the calculation of PAI indicators.</div>
                                <div className="extra-extra-small-text pai-fourth-text-desktop font-style-montserrat-family-regular color-white">Lynxai offers a comprehensive solution to address this issue with its automated reporting system. The system draws from a dependable data source on listed companies and features a user-friendly reporting framework.</div>
                            </div>
                            <div className="pai-fourth-points">
                                <img className="pai-fourth-icon" src={identify_liabilities_icon} alt="img"/>
                                <div className="small-text pai-fourth-main-text-desktop font-style-montserrat-family-regular color-white">Comply and optimize your workload when creating PAI statements</div>
                                <div className="extra-extra-small-text pai-fourth-text-desktop font-style-montserrat-family-regular color-white">Our research teams and regulation department monitor all up-to-date disclosures and regulations on the financial markets which gives our clients 100% guarantee in compliance with all the changes and updates in regulations.</div>
                            </div>
                            <div className="pai-fourth-points">
                                <img className="pai-fourth-icon" src={guarantee_compliance_icon} alt="img"/>
                                <div className="small-text pai-fourth-main-text-desktop font-style-montserrat-family-regular color-white">Data complexity</div>
                                <div className="extra-extra-small-text pai-fourth-text-desktop font-style-montserrat-family-regular color-white">Analyzing PAI data can be challenging for investors who may lack resources and legal expertise, leading to incomplete or inaccurate reporting. Our technological solution provides secure assurance and simplifies the analysis process for investors.</div>
                            </div>
                            <div className="pai-fourth-points">
                                <img className="pai-fourth-icon" src={real_time_icon_white} alt="img"/>
                                <div className="small-text pai-fourth-main-text-desktop font-style-montserrat-family-regular color-white">Automatically request data disclosures for companies in your portfolio where there is missing data</div>
                                <div className="extra-extra-small-text pai-fourth-text-desktop font-style-montserrat-family-regular color-white">Lynxai platform solves this problem and gives an opportunity to both investors and private companies to create productive dialogue via this solution. Lynxai helps both parties in building trust and sustainable business strategies.</div>
                            </div>
                        </div>
                        <div className="pai-fourth-icons-div-mobile">
                            <TransparentThreeRowsCarousel {...first_carousel_data} ></TransparentThreeRowsCarousel>
                        </div>
                        <div className="button-div">
                            <BlueButton text="Know More" fitContent="fixed-width" fontSize="small-text"></BlueButton>
                        </div>
                    </div>
                    <div className="pai-fourth-image-div">
                        <img className="image-diagram" src={lynxai_rcr_diagram} alt="img"/>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default PAIFourth;