import React from 'react';

import "./AboutUsSecond.scss";

const lynxai_logo_black = require('../../assets/images/lynxai_logo_black.png');

const AboutUsSecond = () => {
  return (
    <div className="about-us-second-div">
      <img className="about-us-second-icon" src={lynxai_logo_black} alt="img" />
      <p className="three-reasons-title extra-large-text  font-style-montserrat-family-regular color-black">Our Mission</p>
      <p className="about-us-second-text medium-text font-style-montserrat-family-regular color-black">We have the mission of changing the
        world through technology. By helping asset managers efficiently invest in sustainable products, we can impact the world in the most effective way possible.</p>
    </div>
  );
}
export default AboutUsSecond;
