
import React from 'react';

import FormMessage from '../FormMessage/FormMessage';

import "./ContactUsFirst.scss"

const ContactUsFirst = (props) => {

    return (
        <div className="get-in-touch-div">
            <div className="contact-us-first-text-div">
                <div className="medium-text font-style-montserrat-family-regular color-blue">Contact Us</div>
                <h1 className="extra-extra-large-text contact-us-first-title font-style-montserrat-family-regular color-black">Get in Touch <br /> With Us</h1>
                <p className="small-text contact-us-first-main-text font-style-montserrat-family-regular color-black">Lynxai is a data-driven platform that makes ESG investment more efficient, by helping the asset management industry to effortlessly comply with regulations, and to have access to diverse and reliable ESG data filtered according to the clients standards.</p>
                <p className="small-text contact-us-first-main-text font-style-montserrat-family-regular color-black">If you have any questions or comments about our products, processes, or business, please contact us directly. We offer free consultations and quotes for project specifications.</p>
            </div>
            <FormMessage messagePlaceholder="Message*" mailTemplate="contact_request_website"></FormMessage>
        </div>
    );
}

export default ContactUsFirst;