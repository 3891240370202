import React from 'react';
import { MainDiv } from "../../assets/styles/global";

import AboutUsFirst from "../../components/AboutUsFirst/AboutUsFirst";
import AboutUsSecond from "../../components/AboutUsSecond/AboutUsSecond";
import AboutUsThird from "../../components/AboutUsThird/AboutUsThird";
import AboutUsFourth from "../../components/AboutUsFourth/AboutUsFourth";
import AboutUsFifth from "../../components/AboutUsFifth/AboutUsFifth";

const AboutUs = () =>{
  return (
    <MainDiv>
      <AboutUsFirst></AboutUsFirst>
      <AboutUsSecond></AboutUsSecond>
      <AboutUsThird></AboutUsThird>
      <AboutUsFourth></AboutUsFourth>
      <AboutUsFifth></AboutUsFifth>
    </MainDiv>
  );
}
export default AboutUs;
