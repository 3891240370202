import React from "react";

import "./HomepageFifth.scss"

import BlueButton from "../BlueButton/BlueButton"

const esg_needs_diagram = require('../../assets/images/esg_needs_diagram.png');

const HomepageFifth = () => {
    return (
        <div className="homepage-fifth-div">
            <div className="homepage-fifth-dashboard-text">
                <p className="homepage-fifth-diagram-text extra-large-text font-style-montserrat-family-regular color-black">All your ESG needs <br />under one platform</p>
                <div className="blue-button-div"><BlueButton link="/contact-us" text="Talk with Us" fitContent="fixed-width" fontSize="small-text"></BlueButton></div>
            </div>
            <img className="esg-needs-diagram" src={esg_needs_diagram} alt="img"></img>
        </div>
    );
}

export default HomepageFifth;